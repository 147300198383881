<template>
  <div class="user-main">
    <div class="user-main-head">
      <img @click="clickHandler('/order')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>发票详情</div>
    </div>
    <div style="width: 100%;background: #FFFFFF;display: flex;justify-content: center">
      <div class="main-login-form">
        <div class="main-login-form-input">
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              开票编号
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.billCode }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;min-height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              开票内容
            </div>
            <div style="width: 554px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.productTitles?.split('@#@').join(' ') }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              开票时间
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.createDateTimeStr }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              发票金额
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.turnover }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              开票状态
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{
                item.status === 1 ? '开票中' : item.status === 2 ?
                  '开票完成' : item.status === 3 ? '已下载' : '开票失败'
              }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              操作
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              <div v-show="item.status === 2 || item.status === 3" class="no-1"
                   @click="download()">下载
              </div>
              <div v-show="item.status !== 2 && item.status !== 3" class="no2-1"
                   @click="">下载
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Billdetail",
  data() {
    return {
      item: {},
    }
  },
  created() {
    console.log(this.$route.params)
    this.item = this.$route.params
  },
  methods: {
    download() {
      if (!this.item.filePath) {
        this.$message.warning("下载失败，请联系客服")
        return
      }
      this.item.filePath.split("@#@").forEach((item, index) => {
        fetch(item).then(res => res.blob().then(blob => {
          let a = document.createElement('a');
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = this.item.billCode + "-发票（微思翻译wesitrans.com）.pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        }));
      })

    },
    clickHandler(url) {
      this.$router.push(url)
    },
  }
}
</script>

<style lang="less" scoped>
.no-1 {
  cursor: pointer;
  padding: 5px 14px;
  width: 58px;
  height: 35px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.no2 {
  cursor: pointer;
  padding: 5px 14px;
  width: 86px;
  height: 35px;
  background: #edf2fc;
  border: 1px solid #edf2fc;
  border-radius: 2px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c0c4cc;
}
.timeButton {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #efeded;
  font-size: 21px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  color: #333333;
  display: flex;
  justify-content: center;
}

.timeButtonClick {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #1c50a7;
  font-size: 18px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.main-login-form {
  width: 704px;
  height: 1000px;
  background: #FFFFFF;

  .main-login-form-input {
    width: 704px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}
</style>
