<template>
  <div class="user-main">
    <div class="user-main-head">
      <img @click="clickHandler('/account')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>修改密码</div>
    </div>
    <div class="main-login-form">
      <div class="main-login-form-input" v-show="!isSuccess">
        <el-form class="demo-ruleForm" :model="updatePassForm" ref="updatePassForm" :rules="updatePassFormRules">
          <el-form-item prop="oPass">
            <el-input type="password" v-model="updatePassForm.oPass" placeholder="原密码"></el-input>
          </el-form-item>
          <el-form-item prop="nPass">
            <el-input type="password" v-model="updatePassForm.nPass" placeholder="新密码"></el-input>
          </el-form-item>
          <el-form-item prop="aPass">
            <el-input type="password" v-model="updatePassForm.aPass" placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 30px;display: flex;align-items: center;justify-content: center;">
            <span style="padding: 20px 50px;
            background: rgb(24, 68, 130);
            border-radius: 2px;
            font-size: 24px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: rgb(255, 255, 255);" @click="submitUpadatePass">确认修改</span>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="isSuccess">
        <div>
          <div style="text-align: center;
    margin-top: 30%;
font-size: 36px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
line-height: 56px;">
            <span class="icon-success"></span><span style="margin-left: 16px">重置成功</span>
          </div>
          <div style="    margin-left: 50%;
          transform: translate(-25%, 45px);">
            <div style="
            cursor: pointer;
            width: 200px;
            height: 60px;
            border: 1px solid #1c50a7;
            border-radius: 3px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 40px;
            font-size: 36px;
            font-family: Noto Sans S Chinese !important;
            font-weight: 400;
            color: #1c50a7;" @click="clickHandler('/account')">返回
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RePass",
  data() {
    return {
      isSuccess: false,
      updatePassForm: {
        oPass: '',
        nPass: '',
        aPass: ''
      },
      updatePassFormRules: {
        oPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'}
        ],
        nPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'}
        ],
        aPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'},
          {validator: this.validatePass, trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    submitUpadatePass() {
      const that = this
      this.$refs.updatePassForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.get("/api/account/updatePass?oPass=" + this.updatePassForm.oPass + "&nPass=" + this.updatePassForm.nPass + "&aPass=" + this.updatePassForm.aPass).then(res => {
          if (res.data === 200) {
            that.isSuccess = true
            that.messages = ""
            return;
          }
          if (res.data === 401) {
            that.messages = "密码错误更新失败"
            return;
          }
          that.messages = "更新失败请重试！";
        }).catch(err => {
          that.messages = "更新失败请重试！";
        })
      });
    },
    validatePass(rule, value, callback) {
      if (this.updatePassForm.nPass !== value) {
        callback(new Error('两次密码输入不一致'))
        return;
      }
      callback()
    },
    clickHandler(url) {
      this.$router.push(url)
    },
  }
}
</script>

<style lang="less" scoped>

.timeButton {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #efeded;
  font-size: 21px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  color: #333333;
  display: flex;
  justify-content: center;
}

.timeButtonClick {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #1c50a7;
  font-size: 18px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.main-login-form {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 704px;
  height: 1000px;
  background: #FFFFFF;
  padding-top: 60px;

  .main-login-form-input {
    width: 625px;
    display: flex;
    justify-content: center;

    /deep/ .demo-ruleForm {
      .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
        height: 65px;
        border: 1px solid #FBAFAF;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
      }

      .el-button--primary {
        width: 624px;
        height: 70px;
        background: #184482;
        border-radius: 3px;
        margin-top: 31px;
        font-size: 30px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
      }

      .el-form-item__error {
        color: #F56C6C;
        font-size: 20px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
      }

      .el-form-item {
        margin-bottom: 31px;
      }

      .el-form-item__content {
        margin-left: 76px !important;
      }

      .el-input {
        height: 65px;
        width: 625px;

        .el-input__inner {
          height: 65px;
          border: 1px solid #DDDDDD;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          font-size: 24px;
        }
      }
    }
  }
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}
</style>
