<template>
  <div>
    <div v-show="!isH5" style="width: 100%;overflow: hidden">
      <el-main id="recharge-div-one">
        <div
          style="text-align: left;font-size: 14px;font-family: Noto Sans S Chinese;font-weight: 500;color: #333333;">
          <div style="width: 890px;height: 60px;">
          <span class="title-font" style="margin-left: 12px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;">开票记录</span>
          </div>
          <div>
            <el-table
              ref="multipleTable"
              :data="tableDataBill"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                prop="billCode"
                label="开票编号"
                width="180">
              </el-table-column>
              <el-table-column
                prop="productTitles"
                label="开票内容"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.productTitles.split('@#@').join(' ') }}
                </template>
              </el-table-column>
              <el-table-column
                prop="createDateTimeStr"
                label="开票时间"
                width="160">
              </el-table-column>
              <el-table-column
                prop="turnover"
                label="发票金额"
                width="80"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="status"
                label="开票状态"
                width="100">
                <template slot-scope="scope">
                  <div>{{
                      scope.row.status === 1 ? '开票中' : scope.row.status === 2 ?
                        '开票完成' : scope.row.status === 3 ? '已下载' : '开票失败'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <div v-show="scope.row.status === 2 || scope.row.status === 3" class="no-1"
                       @click="download(scope.row)">下载
                  </div>
                  <div v-show="scope.row.status !== 2 && scope.row.status !== 3" class="no2-1"
                       @click="()=>void(0)">下载
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div style="width: 100%;display:flex;justify-content: flex-end;margin-top: 10px "
                 v-show="totalBill!==0">
              <el-pagination
                :page-size="5"
                @current-change="handleCurrentChangeBill"
                background
                layout="prev, pager, next"
                :total="totalBill">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-main>
      <el-alert
        title="如有需要，可添加微思翻译在线服务QQ号码：1077258421，进行沟通。"
        type="warning">
      </el-alert>
      <el-main id="recharge-div">
        <div style="width: 860px;height: 60px;display: flex;justify-content: space-between">
          <span class="title-font" style="margin-left: 12px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;">历史订单</span>
          <div style="display: flex;align-items: center;" v-show="multipleSelection.length>0">
            <div>合计金额:<span style="color: rgb(225, 63, 63);margin-left: 10px;margin-right: 5px;">¥</span>
              <span style="font-size: 30px;color:rgb(225, 63, 63);margin-right: 10px ">{{ totalMoney }}</span></div>
            <div class="no-he"
                 @click="handleOpenAll()">合并申请
            </div>
          </div>
        </div>
        <!-- 充值显示部分套餐选项 -->
        <div id="select-div">
          <el-table
            header-row-class-name="head"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              prop="batchId"
              width="50"
              :selectable="selectable"
            >
            </el-table-column>
            <el-table-column
              prop="batchId"
              label="订单编号"
              width="180">
            </el-table-column>
            <el-table-column
              prop="productTitle"
              label="订单信息"
              show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.productTitle }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createDateTimeStr"
              label="下单时间"
              width="160">
            </el-table-column>
            <el-table-column
              prop="turnover"
              label="订单金额"
              width="80"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="status"
              label="申请状态"
              width="100">
              <template slot-scope="scope">
                <div>{{ scope.row.status === undefined || scope.row.status === null ? '未申请' : '已申请' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <div v-show="scope.row.status === undefined || scope.row.status === null" class="no"
                     @click="handleOpen(scope.$index, scope.row)">申请发票
                </div>
                <div v-show="scope.row.status !== undefined && scope.row.status !== null" class="no2"
                     @click="()=>void(0)">申请发票
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;display:flex;justify-content: flex-end;margin-top: 10px "
               v-show="total!==0">
            <el-pagination
              :page-size="8"
              @current-change="handleCurrentChange"
              background
              layout="prev, pager, next"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-main>
      <el-dialog width="546px" :visible.sync="dialogFormVisible">
        <div slot="title" style="border-bottom: 1px #DDDDDD solid;padding-bottom: 15px;">
          <div style="text-align: left;margin-left: 55px;">
          <span style="text-align:left;font-size: 18px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;">发票抬头</span>
          </div>
        </div>
        <el-form :model="form" ref="updatePassForm" :rules="updatePassFormRules">
          <el-form-item label="单位名称：" :label-width="formLabelWidth" class="custome-label" prop="company">
            <el-input class="custome-input" v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="单位税号：" :label-width="formLabelWidth" class="custome-label" prop="companyCode">
            <el-input class="custome-input" v-model="form.companyCode"></el-input>
          </el-form-item>
          <el-form-item label="开票内容：" :label-width="formLabelWidth" class="custome-label custome-label-radio"  prop="content">
            <el-radio v-model="form.content" label="TECH_SVC">信息技术服务*技术服务费</el-radio>
            <el-radio v-model="form.content" label="TRANS_SVC">信息技术服务*翻译服务费</el-radio>
            <el-radio v-model="form.content" label="AUDIT_SVC">信息技术服务*审校费</el-radio>
            <el-radio v-model="form.content" label="OTHER_SVC">如需其他，请添加QQ1077258421，进行沟通</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="height: 65px;margin-top: -5px;">
          <div style="text-align: center;width: 100%;">
          <span class="submitUpadatePass" style="
              padding: 10px 45px;
              background: #184482;
              border-radius: 2px;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #FFFFFF;" @click="submit">申请发票</span></div>
        </div>
        <div><span
          style="color: #df2525;font-size: 14px;font-family: Noto Sans S Chinese !important;">{{ messages }}</span>
        </div>
      </el-dialog>
    </div>
    <div v-show="isH5" class="user-main">
      <div class="user-main-head">
        <img @click="clickHanler('/index')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
        <div>订单详情</div>
      </div>
      <div class="user-main-select">
        <div @click="()=>activeName='fp'" :class="activeName==='fp'?'ischeck':''">开票记录</div>
        <div @click="()=>activeName='dd'" :class="activeName==='dd'?'ischeck':''">历史订单</div>
      </div>
      <div v-show="activeName==='fp'">
        <div class="infinite-list-wrapper"
             style="margin-top:15px;width: 768px;display: flex;flex-flow: column;align-items: center">
          <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;height:900px;">
            <li v-for="(item,i) in tableH5DataBill" :key="i" class="infinite-list-item"
                infinite-scroll-disabled="disabled">
              <div style="width: 768px;display: flex;cursor: pointer" @click="toPath('Billdetail',item)">
                <div
                  style="margin-left: 34px;width:130px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
                  开票编号
                </div>
                <div
                  style="width:400px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
                  {{ item.billCode }}
                </div>
                <div
                  style="width:150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
                  {{
                    item.status === 1 ? '开票中' : item.status === 2 ?
                      '开票完成' : item.status === 3 ? '已下载' : '开票失败'
                  }}
                </div>
                <div style="font-size: 24px;ont-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </li>
          </ul>
          <p v-if="loading" style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
            加载中...</p>
          <p v-if="noMore" style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
            没有更多了</p>
        </div>
      </div>
      <div v-if="activeName==='dd'">
        <div class="infinite-list-wrapper"
             style="margin-top:15px;width: 768px;display: flex;flex-flow: column;align-items: center">
          <ul class="infinite-list" v-infinite-scroll="loadDD" style="overflow:auto;height:900px;">
            <li v-for="(item,i) in tableH5Data" :key="i" class="infinite-list-item"
                infinite-scroll-disabled="disabledDD">
              <div style="width: 768px;display: flex;cursor: pointer" @click="toPath('DDdetail',item)">
                <div
                  style="margin-left: 34px;width:130px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
                  订单编号
                </div>
                <div
                  style="width:400px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
                  {{ item.batchId }}
                </div>
                <div
                  style="width:150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
                  {{ item.status === undefined || item.status === null ? '未申请' : '已申请' }}
                </div>
                <div style="font-size: 24px;ont-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </li>
          </ul>
          <p v-if="loadingDD" style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
            加载中...</p>
          <p v-if="noMoreDD" style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
            没有更多了</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      loadingDD: false,
      loading: false,
      activeName: 'fp',
      messages: '',
      isH5: false,
      tableDataBill: [],
      tableH5DataBill: [],
      tableData: [],
      tableH5Data: [],
      multipleSelection: [],
      currentPageBill: 1,
      pageSizeBill: 5,
      currentPage: 1,
      pageSize: 8,
      total: 0,
      totalBill: 0,
      dialogFormVisible: false,
      form: {
        company: '',
        companyCode: '',
        content:'',
        batchId: []
      },
      formLabelWidth: '100px',
      updatePassFormRules: {
        company: [
          {required: true, message: '请输入单位名称', trigger: 'blur'},
          {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
        ],
        companyCode: [
          {required: true, message: '请输入单位税号', trigger: 'blur'},
          {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请选择开票内容', trigger: 'change'},
        ]
      },
      totalMoney: 0
    }
  },
  watch: {
    "multipleSelection": function (value) {
      console.log(value)
      this.form.batchId = []
      this.totalMoney = 0
      value.forEach((val, index) => {

        this.form.batchId.push(val.batchId)
        this.totalMoney = this.add(this.totalMoney, val.turnover, 2)
      })
    }
  },
  beforeMount() {
    this.isH5 = this.isMobile()
  },
  created() {
    this.selectOrderBill()
    this.selectOrder()
    this.getCompany()
    this.loadDD()
  },
  computed: {
    noMore() {
      return this.totalBill >= this.tableH5DataBill.length
    },
    disabled() {
      return this.loading || this.noMore
    },
    noMoreDD() {
      return this.total >= this.tableH5Data.length
    },
    disabledDD() {
      return this.loadingDD || this.noMoreDD
    }
  },
  methods: {
    loadDD() {
      this.loadingDD = true
      this.$http.get("/api/account/selectOrder?currentPage=" + this.currentPage + "&pageSize=" + this.pageSize).then(res => {
        if (res.data && res.data.orders.length > 0) {
          this.tableH5Data.push(...res.data.orders)
          this.total = res.data.total
          this.currentPage = this.currentPage + 1
        }
        this.loadingDD = false
      }).catch(err => {
        this.loadingDD = false
      })
    },
    load() {
      this.loading = true
      this.$http.get("/api/account/selectOrderBill?currentPage=" + this.currentPageBill + "&pageSize=" + this.pageSizeBill).then(res => {
        if (res.data && res.data.orderBills.length > 0) {
          this.tableH5DataBill.push(...res.data.orderBills)
          this.totalBill = res.data.total
          this.currentPageBill = this.currentPageBill + 1
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    clickHanler(url) {
      document.location.href = "https://wesitrans.com/index"
    },
    toPath(name, item) {
      this.$router.push({
        name: name,
        params: item
      });
    },
    add(arg1, arg2, num) {//num为保留位数,不传时不四舍五入
      arg1 = Number(arg1);
      arg2 = Number(arg2);
      var r1 = 0, r2 = 0, m
      try {
        r1 = arg1.toString().split('.')[1].length;
      } catch (e) {
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
      }
      m = Math.pow(10, Math.max(r1, r2));
      if (num || num === 0) {
        return ((arg1 * m + arg2 * m) / m).toFixed(num) //注意：fixed四舍五入大于5会进1 5并不会进1
      }
      return (arg1 * m + arg2 * m) / m
    },
    getCompany() {
      this.$http.get("/api/account/getCompany").then(res => {
        if (res.data && res.data !== '') {
          this.form.company = res.data.company
          this.form.companyCode = res.data.company_code
        }
      }).catch(err => {
      })
    },
    submit() {
      this.$refs.updatePassForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.get("/api/account/applyBill?batchIds=" + this.form.batchId.join(',') +
          "&company=" + this.form.company + "&companyCode=" + this.form.companyCode +
          "&content=" + this.form.content).then(res => {

          if (res.data) {
            if (res.data === 'ok') {
              this.$message.success("已提交申请，请稍后")
              this.dialogFormVisible = false
              this.selectOrder()
              this.selectOrderBill()
              return
            }
            if (res.data === 'need_login') {
              this.$message.warning("登录失效，请重新登录")
              this.dialogFormVisible = false
              setTimeout(() => document.location.href = "https://wesitrans.com/index", 3000);
              return;
            }
            this.messages = res.data
          }
        }).catch(err => {
          this.$message.error("操作异常，请稍后再试")
        })
      })
    },
    handleOpen(index, row) {
      this.form.batchId = []
      this.form.batchId.push(row.batchId)
      this.dialogFormVisible = true
    },
    handleOpenAll() {
      this.dialogFormVisible = true
    },
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    selectable(row, index) {
      return row.status === undefined || row.status === null;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectOrder()
    },
    download(row) {
      console.log(row)
      if (!row.filePath) {
        this.$message.warning("下载失败，请联系客服")
        return
      }
      row.filePath.split("@#@").forEach((item, index) => {
        fetch(item).then(res => res.blob().then(blob => {
          let a = document.createElement('a');
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = row.billCode + "-发票（微思翻译wesitrans.com）.pdf";

          a.click();
          window.URL.revokeObjectURL(url);
        }));
      })

    },
    handleCurrentChangeBill(val) {
      this.currentPageBill = val;
      this.selectOrderBill()
    },
    selectOrder() {
      this.$http.get("/api/account/selectOrder?currentPage=" + this.currentPage + "&pageSize=" + this.pageSize).then(res => {
        if (res.data) {
          this.tableData = res.data.orders
          this.total = res.data.total
        }
      }).catch(err => {
      })
    },
    selectOrderBill() {
      const that = this
      this.$http.get("/api/account/selectOrderBill?currentPage=" + this.currentPageBill + "&pageSize=" + this.pageSizeBill).then(res => {

        if (res.data) {
          this.tableDataBill = res.data.orderBills
          this.totalBill = res.data.total
        }
      }).catch(err => {
      })
    }
  }
}
</script>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1C50A7 !important;
  border-color: #1C50A7 !important;
}
.custome-label-radio .el-form-item__content{
  margin-left: 100px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 120px;
}
</style>
<style scoped>

.infinite-list {
  height: 900px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.infinite-list-item {
  margin-top: 15px;
  min-width: 768px;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
}

.ischeck {
  border-bottom: 3px solid #063F95;
}

.user-main-select div:nth-child(1) {
  margin-left: 34px;
  margin-right: 63px;
  height: 80px;
  display: flex;
  align-items: center;
}

.user-main-select div:nth-child(2) {
  height: 80px;
  display: flex;
  align-items: center;
}

.user-main-select {
  width: 768px;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1c50a7;
  color: #FFF;
}

.no2-1 {
  cursor: pointer;
  padding: 5px 14px;
  width: 58px;
  height: 35px;
  background: #edf2fc;
  border: 1px solid #edf2fc;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c0c4cc;
}

.no-1 {
  cursor: pointer;
  padding: 5px 14px;
  width: 58px;
  height: 35px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.no2 {
  cursor: pointer;
  padding: 5px 14px;
  width: 86px;
  height: 35px;
  background: #edf2fc;
  border: 1px solid #edf2fc;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c0c4cc;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1C50A7 !important;
  border-color: #1C50A7 !important;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1C50A7;
  border-color: #1C50A7;
}

.no-he {
  cursor: pointer;
  padding: 5px 14px;
  width: 56px;
  height: 20px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 25px;
}

.no {
  cursor: pointer;
  padding: 5px 14px;
  width: 86px;
  height: 35px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.submitUpadatePass:hover {
  padding: 10px 45px;
  background: #1C50A7 !important;
  border-radius: 2px;
  font-size: 14px;
  font-family: "Adobe Heiti Std";
  font-weight: normal;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.submitUpadatePass {
  padding: 10px 45px;
  background: rgb(24, 68, 130);
  border-radius: 2px;
  font-size: 14px;
  font-family: "Adobe Heiti Std";
  font-weight: normal;
  color: rgb(255, 255, 255);
}

.custome-input {
  width: 368px;
  height: 40px;
  border: 0px solid #DDDDDD;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  float: left;
}

.custome-label.el-form-item {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

#recharge-div-one {
  background: #FFFFFF;
  overflow: hidden;
  margin-bottom: 20px;
}

#recharge-div {
  background: #FFFFFF;
  overflow: hidden;
  height: 870px;
  text-align: left;
}
</style>
