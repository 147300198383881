<template>
  <div class="user-main">
    <div class="user-main-head">
      <img @click="clickHandler('/account')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>邮箱修改</div>
    </div>
    <div class="main-login-form">
      <div class="main-login-form-input">
        <el-form label-width="0" class="demo-ruleForm" :model="updateEmailForm" :rules="email_rules" ref="emailRef">
          <el-form-item prop="email">
            <el-input placeholder="请输入邮箱" v-model="updateEmailForm.email"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input style="width: 475px;" placeholder="请输入4位验证码" v-model="updateEmailForm.code"></el-input>
            <img
              style=" border-radius: 3px;float: right;width: 133px;height: 62px;font-family: Noto Sans S Chinese !important;"
              :src="qrcodeUrl" alt="验证码加载失败..."
              @click="getValicode"/>
          </el-form-item>
          <el-form-item prop="newEmailCode">
            <el-input style="width: 475px;" placeholder="请输入6位新邮箱验证码" v-model="updateEmailForm.newEmailCode"
            ></el-input>
            <div class="timeButton" v-show="Verification" @click="handleEmailTimeClick">获取动态密码</div>
            <div class="timeButtonClick" v-show="!Verification">{{ timers }}秒后重新获取</div>
          </el-form-item>
          <div style="text-align: center;font-size: 24px;color: #df2525;font-family: Noto Sans S Chinese !important;">{{ messages }}</div>
          <el-form-item class="login_button">
            <el-button type="primary" @click="submitUpdateEmail()">确认修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReEmail",
  data() {
    return {
      messages: '',
      timers: 60,
      Verification: true,
      qrcodeUrl: "",
      updateEmailForm: {
        email: undefined,
        code: undefined,
        newEmailCode: undefined,
      },
      email_rules: {
        email: [
          {required: true, message: '请输入新邮箱', trigger: 'blur'},
          {pattern:  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/, message: "请输入合法邮箱", trigger: "blur"},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '请输入4位验证码', trigger: 'blur'}
        ],
        newEmailCode: [
          {validator: this.validateNewEmailCode, trigger: 'blur'}
        ]
      },
    }
  },
  mounted() {
    this.getValicode()
  },
  methods: {
    handleEmailTimeClick() {
      this.$refs.emailRef.validate((valid) => {
        if (!valid) {
          return;
        }
        const that = this;
        this.$http.get('/api/account/sendEmailCode?email=' + that.updateEmailForm.email + "&code=" + that.updateEmailForm.code).then(res => {
          if (res.data) {
            that.messages = "";
            that.Verification = false;
            this.auth_timer = setInterval(() => {
              that.timers--;
              if (that.timers <= 0) {
                that.Verification = true;
                that.timers = 60;
                clearInterval(that.auth_timer)
              }
            }, 1000)
            return;
          }
          that.getValicode();
          that.messages = "验证码错误"
          that.Verification = true;
          that.timers = 60;
        }).catch(err => {
          that.getValicode();
          that.messages = "邮箱验证码发送失败"
          that.Verification = true;
          that.timers = 60;
        });
      });
    },
    validateNewEmailCode(rule, value, callback) {
      if (!this.isLogin) {
        callback()
        return
      }
      if (value === null || value === undefined || value === "") {
        callback(new Error('请输入新邮箱验证码'))
        return;
      }
      if (value.length > 6 || value.length < 6) {
        callback(new Error('请输入6位新邮箱验证码'))
        return;
      }
      callback()
    },
    validateEmail(rule, value, callback) {
      if(this.email===value.trim()){
        callback(new Error('邮箱与原邮箱一致'))
        return;
      }
      this.$http.get("/api/account/validateEmail?email=" + value).then(res => {
        if (res.data === 400) {
          callback(new Error('邮箱有误'))
          return;
        }
        if (res.data === 401) {
          callback(new Error('邮箱已被绑定'))
          return;
        }
        callback()
      }).catch(err => {
        callback(new Error('校验出错'));
      });
    },
    submitUpdateEmail() {
      this.isLogin = true;
      const that = this
      this.$refs.emailRef.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.post("/api/account/updateEmail",{
          "email":this.updateEmailForm.email,
          "code":this.updateEmailForm.code,
          "newCode":this.updateEmailForm.newEmailCode
        }).then(res => {
          if (res.data === 200) {
            that.isLogin = false;
            that.updateEmail = false
            that.$message.success("更新成功")
            this.$router.push("/account")
            return;
          }
          that.messages = "更新失败请重试！";
          that.getValicode();
          that.isLogin = false;
        }).catch(err => {
          if (err && err.code === 5) {
            this.$router.push("/login")
          }
          that.isLogin = false;
        })
      });
      this.isLogin = false;
    },
    getValicode() {
      this.qrcodeUrl = '/api/account/getValicode?' + new Date();
    },
    clickHandler(url) {
      this.$router.push(url)
    },
  }
}
</script>

<style lang="less" scoped>
.timeButton {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #efeded;
  font-size: 21px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  color: #333333;
  display: flex;
  justify-content: center;
}

.timeButtonClick {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #1c50a7;
  font-size: 18px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.main-login-form {
  width: 704px;
  height: 1000px;
  background: #FFFFFF;
  margin-top: 30px;
  margin-bottom: 30px;

  .main-login-form-input {
    padding-top: 50px;
    width: 625px;
    display: flex;
    justify-content: center;

    /deep/ .demo-ruleForm {
      .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
        height: 65px;
        border: 1px solid #FBAFAF;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
      }

      .el-button--primary {
        width: 624px;
        height: 70px;
        background: #184482;
        border-radius: 3px;
        margin-top: 31px;
        font-size: 30px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
      }

      .el-form-item__error {
        color: #F56C6C;
        font-size: 20px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
      }

      .el-form-item {
        margin-bottom: 31px;
      }

      .el-form-item__content {
        margin-left: 76px !important;
      }

      .el-input {
        height: 65px;
        width: 625px;

        .el-input__inner {
          height: 65px;
          border: 1px solid #DDDDDD;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          font-size: 24px;
        }
      }
    }
  }
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}
</style>
