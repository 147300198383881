<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {}
    },
    methods: {
      setCookie(name, value, expiredays) {
        const exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
      },
      // 3、iframe 加载后获取一次导出数据
      onExportPageLoad() {
        let iframePage = document.getElementById('iframe-page');
        iframePage.contentWindow.postMessage(
          {
            type: 'getToken'
          },
          process.env.VUE_APP_STORAGE
        );
        iframePage.contentWindow.postMessage(
          {
            type: 'getAuthContext'
          },
          process.env.VUE_APP_STORAGE
        );
      },
      // 4、传 id 删除一条数据
      delete(id) {
        var iframePage = document.getElementById('iframe-page');
        iframePage.contentWindow.postMessage(
          {
            type: 'delete',
            key: id,
          },
          process.env.VUE_APP_STORAGE
        );
      },

      // 5、清空数据
      clear() {
        var iframePage = document.getElementById('iframe-page');
        iframePage.contentWindow.postMessage(
          {
            type: 'clear',
          },
          process.env.VUE_APP_STORAGE
        );
      }
    }
  }
</script>

<style>
  #app {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
</style>
