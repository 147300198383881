<template>
  <div>
    <el-container style="background: #F5F5F5;min-width: 1200px;" v-show="!isH5">
      <el-header style="height: 87px;background: #FFFFFF;width: 100%">
        <center>
          <div style="width: 1200px">
            <a @click="clickHanler('/index')" href="javascript:void(0)">
              <img src="../assets/logo-big.png" alt="" style="height: 50px;margin-top: 20px;float: left">
            </a>
            <div style="    float: right; margin-top: 15px;">
              <el-menu v-show="this.$route.path!=='/agreement'" :class="'el-menu-index'"
                       :active-text-color="'#184687'"
                       :text-color="'black'"
                       mode="horizontal">
                <el-menu-item :class="'el-menu-item-index'"
                              @click="clickHanler('/index')">首页
                </el-menu-item>
                <el-menu-item :class="'el-menu-item-index'"
                              @click="clickHanler('/translate')">
                  立即翻译
                </el-menu-item>
                <el-menu-item :class="'el-menu-item-index'"
                              @click="clickHanler('/translate-pro')">
                  人工翻译
                </el-menu-item>
                <el-menu-item :class="'el-menu-item-index'"
                              @click="clickHanler('/convert')">pdf转换
                </el-menu-item>
                <el-menu-item :class="'el-menu-item-indexs'"
                              @click="clickHanler('/list-pro')">历史文档
                </el-menu-item>
                <el-menu-item class="user-right" style=" margin-right: -30px;
    margin-top: -5px;">
                <span class="el-dropdown-link" v-if="!showloginOut">
                  <span style="
                      font-size: 14px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      display: inline-block;
                      width: 86px;
                      overflow: hidden;
                      margin-right: 14px;
                      height: 36px;
                      background: #f5f5f500;
                      border: 1px solid #184687;
                      border-radius: 3px;
                      line-height: 2.5;
                      text-align: center;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #184687;" @click="showIframe('/login')">{{ userInfo }}</span>
                </span>
                  <el-dropdown @command="logOut" v-if="showloginOut">
            <span class="el-dropdown-link">
                    <span :style="'color:black'"
                          style="
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    max-width: 150px;
                    overflow: hidden;
                    margin-right: 14px;" :title="userInfo">{{ userInfo }}</span>
            </span>
                    <el-dropdown-menu v-if="showloginOut" slot="dropdown">
                      <el-dropdown-item style="z-index: 1;font-weight: 800">退出</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <img v-if="userType==='0'" class="user-img" src="../assets/person_no.png" alt="">
                  <img v-if="userType==='1'" class="user-img" src="../assets/child_no.png" alt="">
                  <img v-if="userType==='2'" class="user-img" src="../assets/roaming_no.png" alt="">
                </el-menu-item>
                <el-menu-item class="user-right" style="    margin-left: 30px;margin-top:-5px"
                              @click="clickHanler('/help')">
                  <i class="custome-icon el-icon-question"></i>
                  <span style="padding-left: 8px;width: 27px;
            height: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            ">帮助</span>
                </el-menu-item>
              </el-menu>
            </div>
          </div>
        </center>
      </el-header>
      <center>
        <el-container style="width: 1200px;height: 1270px;">
          <el-aside id="menu-div">
            <el-menu :default-active="this.$route.fullPath">
              <div style="line-height: 100px;text-align: left;padding-left: 22px;">
                <img src="../assets/person.jpg" alt="" style="position: absolute;
    margin-left: -22px;
    width: 300px;"/>
                <span style="position:relative;z-index: 10;font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;">个人中心</span>
              </div>
              <el-menu-item index="/account" @click="info()">
                <img src="../assets/zhanghao.png" style="width: 22px;"/>
                <span style="margin-left: 12px">账号设置</span>
              </el-menu-item>
              <el-menu-item index="/meal" @click="meal()">
                <img src="../assets/vip.png" style="width: 22px;"/>
                <span style="margin-left: 12px">用户充值</span>
              </el-menu-item>
              <el-menu-item index="/order" @click="order()">
                <img src="../assets/order.png" style="width: 22px;"/>
                <span style="margin-left: 12px">发票申请</span>
              </el-menu-item>
              <el-menu-item v-if="hasShow" index="/dictionary"
                            @click="dictionary()">
                <img src="../assets/map.png" style="width: 20px;"/>
                <span style="margin-left: 12px">定义字典</span>
              </el-menu-item>
            </el-menu>
          </el-aside>
          <el-main id="main-div" style="width: 890px;overflow:hidden;height: 1250px">
            <router-view></router-view>
          </el-main>
        </el-container>
      </center>
      <el-footer id="foot-div" :class="'footer'" v-if="this.$route.name!=='DocView'">
        <span>© 2020&nbsp;北京容智科技发展有限公司</span>
        <span>&nbsp;&nbsp;&nbsp;京ICP备19036872号-3  All rights reserved.&nbsp;&nbsp;&nbsp;</span>
        <img src="../assets/download.png" alt="" style="transform: translate(1px, 3px);">
        <span>&nbsp;&nbsp;京公网安备&nbsp;11011402010815号</span>
      </el-footer>
    </el-container>
    <div v-show="isH5">
      <router-view></router-view>
      <div style="width: 768px;height: 125px;background: #f5f5f5;display: flex;
    align-items: center;
    justify-content: center;">
        <div style="width: 667px;
height: 64px;
font-size: 24px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #00233E;
line-height: 36px;text-align: center">
          © 2020 北京容智科技发展有限公司 京ICP备19036872号-3 All
          rights reserved. 京公网安备 11011402010815号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getTest} from '@/api/test'

export default {
  name: 'Index',
  data() {
    return {
      hasShow: false,
      payCenterDialogVisible: false,
      groupUserInfos: [],
      url: '/login',
      activeIndex: '1',
      userInfo: '登录/注册',
      iframeState: false,
      showloginOut: false,
      showHeader: true,
      groupUserInfo: '',
      userType: '',
      isH5: false,
    }
  },
  created() {
    this.hasShowFun()
    const that = this;
    this.$http.post('/api/getUserInfo', {
      mealType: this.meal
    }).then(function (response) {
      if (!response.data) {
        window.open(process.env.VUE_APP_INDEX, "_self")
        return
      }
      let hasPerson = false;
      response.data.forEach((item, index) => {
        if (hasPerson) {
          return
        }
        hasPerson = item.type === "Child" || item.type === "Person";
      })
      if (!hasPerson) {
        window.open(process.env.VUE_APP_INDEX, "_self")
        return
      }
      localStorage.setItem("auth-context", JSON.stringify(response.data))
      that.getUserInfo()
    }).catch(function (error) {
      window.open(process.env.VUE_APP_INDEX, "_self")
    });
    this.isH5 = this.isMobile()
  },
  methods: {
    hasShowFun() {
      const that = this;
      this.$http.get('/api/hasMap').then(function (response) {
        that.hasShow = response.data
      }).catch(function (error) {
        that.hasShow = false
      });
    },
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    setCookie(name, value, expiredays) {
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    },
    getUrlParameter(route) {
      //decodeURIComponent() 函数可对 encodeURIComponent() 函数编码的 URI 进行解码。
      let url = decodeURIComponent(route).split('?');
      url = url[url.length - 1];
      let obj = {};
      url.split('&&').map((query) => {
        let temp = query.split('=');
        obj[temp[0]] = temp[1];
      });
      return obj;
    },
    showIframe(url) {
      const newUrl = process.env.VUE_APP_LOGIN + url;
      const width = $(window).width();
      if (width < 1100) {
        this.$layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: [newUrl, 'no'],
        });
      } else {
        this.$layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: [newUrl, 'no'],
        });
      }
    },
    logOut() {
      this.$http.get("/api/logOut").then(res => {
        if (res.data) {
          localStorage.removeItem("auth-context")
          this.setCookie('token', "", 2 * 1000 * 60 * 60);
          window.open("https://wesitrans.com/", "_self");
        }
      }).catch(err => {
      })
    },
    getUserInfo() {
      localStorage.setItem("groupUser", "");
      localStorage.setItem("userType", "")
      const user = localStorage.getItem('auth-context');
      if (!user) {
        return
      }
      const userJson = JSON.parse(user);
      const Base64 = require('js-base64').Base64;
      userJson.forEach(item => {
        if (item.type === 'Person' || item.type === 'Child') {
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          this.userInfo = realName
          if (this.groupUserInfo === '') {
            this.groupUserInfo = '机构登录';
          }
          this.showloginOut = true
          if (item.isRoaming) {
            this.userType = "2"
            localStorage.setItem("userType", "2");
          } else {
            if (item.type === 'Person') {
              this.userType = "0"
              localStorage.setItem("userType", "0");
            } else {
              this.userType = "1"
              localStorage.setItem("userType", "1");
            }
          }
        }
        if (item.type === 'Group') {
          this.groupUserInfos.push(item)
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          localStorage.setItem("groupUser", realName);
          this.groupUserInfo = realName;
          this.url = process.env.VUE_APP_WEB_LOGIN_URL;
        }
      });
    },
    meal() {
      this.$router.push('/meal')
    },
    info() {
      this.$router.push('/account')
    },
    order() {
      this.$router.push('/order')
    },
    dictionary() {
      this.$router.push('/dictionary')
    },
    clickHanler(url) {
      if (url === '/translate-pro') {
        window.open('https://pro.wesitrans.com/index', "_self");
        return
      }
      let newUrl = process.env.VUE_APP_INDEX + url
      window.open(newUrl, "_self");
    },
    test() {
      getTest().then(testResponse => {
        alert(testResponse.data)
      })
    }
  }
}
</script>

<style scoped>
.user-img {
  width: 16px;
}

#menu-div .el-menu-item.is-active {
  background-color: #F3F6FB;
  border-left: 4px #1C50A7 solid;
  padding-left: 16px !important;
  height: 50px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 45px;
  text-align: left;
}

#menu-div .el-menu-item {
  height: 50px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 45px;
  text-align: left;
}

#menu-div {
  border: 1px solid #dddddd;
}

.footer {
  background-color: #184687;
  color: #ffffff;
  text-align: center;
  height: 140px !important;
  line-height: 1000%;
  float: left;
  width: 100%;
  font-size: 14px;
}

.submenuslogin {
  margin-top: -2px !important;
  margin-left: 40px !important;
}

/deep/ .el-menu--horizontal > .el-menu-item {
  float: left;
  height: 72px;
  line-height: 60px;
  margin: 0;
}

.user-right {
  font-size: 16px !important;
}

.el-menu--horizontal > .el-menu-item.user-right.is-active {
  border-bottom: 3px solid #18468700 !important;
}

.icon-help::after {
  content: '？';
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.icon-help {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: #666666;
  border-radius: 50%;
  margin-right: 5px
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}

.el-menu-item-index {
  line-height: 60px !important;
  margin-right: 30px !important;
  font-size: 14px !important;
  height: 100% !important;
}

.el-menu-item-indexs {
  line-height: 60px !important;
  font-size: 14px !important;
  height: 100% !important;
  margin-right: 50px !important;
}

.el-header-index {
  width: 100%;
  height: 80px !important;
  background-color: white;
  text-align: -webkit-right;
}

.el-header-index .img {
  width: 150px;
  height: 40px;
}

#menu-div {
  margin-top: 21px;
  width: 290px;
  height: 1230px;
  background: #FFFFFF;
  float: left;
  overflow-x: hidden;
}

#main-div {
  height: 926px;
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

#foot-div {
  height: 140px !important;
  background: #194687;
  text-align: center;
}

#foot-font {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 32px;
}
</style>
