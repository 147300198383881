<template>
  <div class="user-main">
    <div class="user-main-head">
      <img @click="clickHandler('/order')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>订单详情</div>
    </div>
    <div style="width: 100%;background: #FFFFFF;display: flex;justify-content: center">
      <div class="main-login-form">
        <div class="main-login-form-input">
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              订单编号
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.batchId }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;min-height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              订单信息
            </div>
            <div style="width: 554px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.productTitle }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              下单时间
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.createDateTimeStr }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              订单金额
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{ item.turnover }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              申请状态
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              {{
                item.status === undefined || item.status === null ? '未申请' : '已申请'
              }}
            </div>
          </div>
          <div style="width: 704px;height: 2px;background: #DDDDDD;"></div>
          <div style="display: flex;height: 90px;align-items: center;">
            <div style="width: 150px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              操作
            </div>
            <div style="font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #333333;">
              <div v-show="item.status === undefined || item.status === null" class="no"
                   @click="handleOpen()">申请发票
              </div>
              <div v-show="item.status !== undefined && item.status !== null" class="no2"
                   @click="">申请发票
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Billdetail",
  data() {
    return {
      item: {},
    }
  },
  created() {
    this.item = this.$route.params
  },
  methods: {
    handleOpen() {
      this.$router.push({
        name: "ApplyBill",
        params: this.item
      });
    },
    clickHandler(url) {
      this.$router.push(url)
    },
  }
}
</script>

<style lang="less" scoped>
.no {
  cursor: pointer;
  padding: 5px 14px;
  height: 35px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.no2 {
  cursor: pointer;
  padding: 5px 14px;
  height: 35px;
  background: #edf2fc;
  border: 1px solid #edf2fc;
  border-radius: 2px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c0c4cc;
}

.timeButton {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #efeded;
  font-size: 21px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  color: #333333;
  display: flex;
  justify-content: center;
}

.timeButtonClick {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #1c50a7;
  font-size: 18px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.main-login-form {
  width: 704px;
  height: 1000px;
  background: #FFFFFF;

  .main-login-form-input {
    width: 704px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}
</style>
