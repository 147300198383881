import { render, staticRenderFns } from "./DDdetail.vue?vue&type=template&id=ef3f6028&scoped=true&"
import script from "./DDdetail.vue?vue&type=script&lang=js&"
export * from "./DDdetail.vue?vue&type=script&lang=js&"
import style0 from "./DDdetail.vue?vue&type=style&index=0&id=ef3f6028&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3f6028",
  null
  
)

export default component.exports