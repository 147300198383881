import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../components/Index";
import Meal from "../components/Meal";
import Account from "../components/Account";
import RePass from "../components/RePass";
import RePhone from "../components/RePhone";
import Order from "@/components/Order";
import Dictionary from "@/components/Map";
import ReEmail from "@/components/ReEmail";
import DDdetail from "@/components/DDdetail"
import Billdetail from "@/components/Billdetail"
import ApplyBill from "@/components/ApplyBill"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/account',
    component: Index,
    children: [
      {
        name: "Meal",
        path: '/meal',
        component: Meal
      }, {
        name: "Account",
        path: '/account',
        component: Account
      }, {
        name: "RePass",
        path: '/rePass',
        component: RePass
      }, {
        name: "RePhone",
        path: '/rePhone',
        component: RePhone
      }, {
        name: "ReEmail",
        path: '/reEmail',
        component: ReEmail
      }, {
        name: "Order",
        path: '/order',
        component: Order
      }, {
        name: "DDdetail",
        path: '/orderDetail',
        component: DDdetail
      }, {
        name: "Billdetail",
        path: '/billDetail',
        component: Billdetail
      }, {
        name: "ApplyBill",
        path: '/applyBill',
        component: ApplyBill
      }, {
        name: "Dictionary",
        path: '/dictionary',
        component: Dictionary
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
/*router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/resetPassword' ||
    to.path === '/index' || to.path === '/try' || to.path === '/register'
    || to.path === '/agreement' || to.path === '/recommendations' || to.path === '/payTip') {
    next();
    return;
  }
  if (to.path !== '/login' && to.path !== '/resetPassword' &&
    to.path !== '/register' && to.path !== '/agreement' && to.path !== '/recommendations'
    && to.path !== '/try' && to.path !== '/payTip'
    && !localStorage.getItem('token') && !localStorage.getItem('auth-context')) {
    next("/index");
    return;
  }
  next();
})*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
