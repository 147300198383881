<template>
  <div class="user-main">
    <div class="user-main-head">
      <img @click="clickHandler('/order')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>发票申请</div>
    </div>
    <div class="main-login-form">
      <div class="main-login-form-input">
        <el-form label-width="0" class="demo-ruleForm" :model="form" :rules="updatePassFormRules" ref="updatePassForm">
          <el-form-item prop="company">
            <el-input placeholder="单位名称：" v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item prop="companyCode">
            <el-input placeholder="单位税号：" v-model="form.companyCode"></el-input>
          </el-form-item>
          <el-form-item prop="content" class="radio-content">
            <el-radio v-model="form.content" label="TECH_SVC">信息技术服务*技术服务费</el-radio>
            <el-radio v-model="form.content" label="TRANS_SVC">信息技术服务*翻译服务费</el-radio>
            <el-radio v-model="form.content" label="AUDIT_SVC">信息技术服务*审校费</el-radio>
            <el-radio v-model="form.content" label="OTHER_SVC">如需其他，请添加QQ1077258421，进行沟通</el-radio>
          </el-form-item>
          <el-form-item class="login_button">
            <el-button type="primary" @click="submit()">申请发票</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplyBill",
  data() {
    return {
      item: {},
      form: {
        company: '',
        companyCode: '',
        content: '',
        batchId: []
      },
      formLabelWidth: '100px',
      updatePassFormRules: {
        company: [
          {required: true, message: '请输入单位名称', trigger: 'blur'},
          {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
        ],
        companyCode: [
          {required: true, message: '请输入单位税号', trigger: 'blur'},
          {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请选择开票内容', trigger: 'change'},
        ]
      },
    }
  },
  mounted() {
    this.item = this.$route.params
    this.form.batchId.push(this.item.batchId)
  },
  methods: {
    submit() {
      this.$refs.updatePassForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.get("/api/account/applyBill?batchIds=" + this.form.batchId.join(',') +
          "&company=" + this.form.company + "&companyCode=" + this.form.companyCode +
          "&content=" + this.form.content).then(res => {
          if (res.data) {
            if (res.data === 'ok') {
              this.item.status = 24
              this.$router.push({
                name: "DDdetail",
                params: this.item
              });
              return
            }
            if (res.data === 'need_login') {
              setTimeout(() => document.location.href = "https://wesitrans.com/index", 3000);
              return;
            }
            this.messages = res.data
          }
        }).catch(err => {
          this.messages = "操作异常，请稍后再试"
        })
      })
    },
    clickHandler(url) {
      this.$router.push({
        name: "DDdetail",
        params: this.item
      });
    },
  }
}
</script>

<style lang="less" scoped>
.timeButton {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #efeded;
  font-size: 21px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  color: #333333;
  display: flex;
  justify-content: center;
}

.timeButtonClick {
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 94px;
  height: 45px;
  float: right;
  background-color: #1c50a7;
  font-size: 18px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.main-login-form {
  width: 704px;
  height: 1000px;
  background: #FFFFFF;
  margin-top: 30px;
  margin-bottom: 30px;

  .main-login-form-input {
    padding-top: 50px;
    width: 625px;
    display: flex;
    justify-content: center;

    /deep/ .demo-ruleForm {
      .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
        height: 65px;
        border: 1px solid #FBAFAF;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
      }

      .el-button--primary {
        width: 624px;
        height: 70px;
        background: #184482;
        border-radius: 3px;
        margin-top: 31px;
        font-size: 30px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
      }

      .el-form-item__error {
        color: #F56C6C;
        font-size: 20px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
      }

      .el-form-item {
        margin-bottom: 31px;
      }

      .el-form-item__content {
        margin-left: 76px !important;
      }

      .el-input {
        height: 65px;
        width: 625px;

        .el-input__inner {
          height: 65px;
          border: 1px solid #DDDDDD;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          font-size: 24px;
        }
      }

      .radio-content {
        .el-form-item__content {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: space-between;
          height: 170px;

          .el-radio {
            .el-radio__inner {
              width: 24px;
              height: 24px;
            }

            .el-radio__label {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}
</style>
