<template>
  <div>
    <div v-show="!isH5" style="width: 100%;overflow: hidden">
      <el-main id="recharge-div-one">
        <div
          style="text-align: left;font-size: 14px;font-family: Noto Sans S Chinese;font-weight: 500;color: #333333;">
          <div style="width: 890px;height: 60px;">
          <span class="title-font" style="margin-left: 12px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;">我的信息</span>
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">用户名</span>
            <span class="custome-title">{{ userid }}</span>
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">昵称</span>
            <span class="custome-title" v-show="!updateName">{{ nick ? nick : "未设置" }}</span>
            <span class="custome-title" v-show="updateName">
            <el-input ref="updateName" @blur="saveName" class="custome-updateName-input"
                      v-model="nick"></el-input></span>
            <span v-if="!hasCurrent" class="custome-update" v-show="!updateName" @click="updateNameClick">
            <img src="../assets/update.png" alt=""/>
            </span>
            <span style="color: rgb(153, 153, 153);
            font-size: 12px;
                padding-left: 60px;
            font-family: Microsoft YaHei;
            font-weight: 400;">{{ message }}</span>
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">密码</span>
            <span class="custome-title">修改密码</span>
            <span v-if="!hasCurrent" class="custome-update" @click="updatePass=true"><img
              src="../assets/update.png"/></span>

          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">手机</span>
            <span class="custome-title">{{ phone }}</span>
            <span v-if="!hasCurrent" class="custome-update" @click="updatePhone=true"><img src="../assets/update.png"/></span>
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">邮箱</span>
            <span class="custome-title">{{ email ? email : "未设置" }}</span>
            <span v-if="!hasCurrent" class="custome-update" @click="updateEmail=true"><img src="../assets/update.png"/></span>
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;">
            <span class="custome-title custome-title-padding">账号类型</span>
            <span class="custome-title">{{ userType === '0' ? "个人账号" : userType === '1' ? "机构子账号" : "机构漫游子账号" }}</span>
            <img v-if="userType==='0'" class="user-img" src="../assets/person_no.png" alt="">
            <img v-if="userType==='1'" class="user-img" src="../assets/child_no.png" alt="">
            <img v-if="userType==='2'" class="user-img" src="../assets/roaming_no.png" alt="">
          </div>
          <div style="margin-left: 12px;width: 890px;height: 60px;"
               v-if="groupUser!==undefined&&groupUser!==null&&groupUser!==''">
            <span class="custome-title custome-title-padding">机构名称</span>
            <span class="custome-title">{{ groupUser }}</span>
          </div>
        </div>
      </el-main>
      <el-main id="recharge-div">
        <div style="width: 890px;height: 60px;">
          <span class="title-font" style="margin-left: 12px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;">账户登录关联</span>
          <span style="color: #999999;font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;">（关联第三方可便捷登录账户）</span>
        </div>
        <!-- 充值显示部分套餐选项 -->
        <div id="select-div">
          <div style="float: left;width: 100px;padding-right: 124px;">
            <span style="margin-left: 30%;"><img src="../assets/qq.png" alt=""/></span>
            <span v-show="okQQ"
                  style="width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            已关联
          </span>
            <span v-show="!okQQ"
                  style="width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            未关联
          </span>
            <span v-if="!hasCurrent"
                  style="margin-top: 25px;width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span v-show="okQQ" class="ok" @click="bindQQ">取消关联</span>
            <span v-show="!okQQ" class="no" @click="bindQQ">关联QQ</span>
          </span>
          </div>
          <div style="float: left;width: 100px;">
            <span style="margin-left: 30%;"><img src="../assets/wx.png" alt=""/></span>
            <span v-show="okWX"
                  style="width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            已关联
          </span>
            <span v-show="!okWX"
                  style="width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            未关联
          </span>
            <span v-if="!hasCurrent"
                  style="margin-top: 25px;width: 100px;float: left;text-align: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span v-show="okWX" class="ok" @click="bindWX">取消关联</span>
            <span v-show="!okWX" class="no" @click="bindWX">关联微信</span>
          </span>
          </div>
        </div>
      </el-main>
      <el-dialog :before-close="handleCloseUdatePass" width="546px" :visible.sync="updatePass">
        <div slot="title" style="border-bottom: 1px #DDDDDD solid;padding-bottom: 15px;">
          <div style="text-align: left;margin-left: 55px;">
          <span style="text-align:left;font-size: 18px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;">修改密码</span>
          </div>
        </div>
        <el-form :model="updatePassForm" ref="updatePassForm" :rules="updatePassFormRules">
          <el-form-item label="原密码：" :label-width="updatePassFormWidth" class="custome-label" prop="oPass">
            <el-input class="custome-input" type="password" v-model="updatePassForm.oPass"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" :label-width="updatePassFormWidth" class="custome-label" prop="nPass">
            <el-input class="custome-input" type="password" v-model="updatePassForm.nPass"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" :label-width="updatePassFormWidth" class="custome-label" prop="aPass">
            <el-input class="custome-input" type="password" v-model="updatePassForm.aPass"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="height: 65px;margin-top: -5px;">
          <div style="text-align: center;width: 100%;">
          <span class="submitUpadatePass" style="
              padding: 10px 45px;
              background: #184482;
              border-radius: 2px;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #FFFFFF;" @click="submitUpadatePass">确认修改</span></div>
        </div>
        <div><span
          style="color: #df2525;font-size: 14px;font-family: Noto Sans S Chinese !important;">{{ messages }}</span>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleCloseUpdatePhone" width="546px" :visible.sync="updatePhone">
        <div slot="title" style="border-bottom: 1px #DDDDDD solid;padding-bottom: 15px;">
          <div style="text-align: left;margin-left: 55px;">
          <span style="text-align:left;font-size: 18px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;">修改手机</span>
          </div>
        </div>
        <el-form :model="updatePhoneForm" ref="phoneRef" :rules="phone_rules">
          <el-form-item prop="phone" style="width: 426px;">
            <el-input class="custome-input" style="width: 426px;float: none" v-model="updatePhoneForm.phone"
                      placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="code" style="width: 426px;">
            <el-input placeholder="请输入4位验证码" class="custome-input" style="margin-left: -170px;width: 255px;float: none"
                      v-model="updatePhoneForm.code"></el-input>
            <span><img style="width: 170px;height: 38px;position: absolute" :src="qrcodeUrl" alt="验证码加载失败..."
                       @click="getValicode"/></span>
          </el-form-item>
          <el-form-item prop="phoneCode" style="width: 426px;">
            <el-input placeholder="请输入6位验证码" class="custome-input" style="margin-left: -170px;width: 255px;float: none"
                      v-model="updatePhoneForm.phoneCode"></el-input>
            <div class="timeButton" v-show="Verification" @click="handleTimeClick">获取动态密码</div>
            <div class="timeButtonClick" v-show="!Verification">{{ timers }}秒后重新获取</div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="height: 65px;margin-top: -5px;">
          <div style="text-align: center;width: 100%;">
          <span class="submitUpdatePhone" style="
              padding: 10px 45px;
              background: #184482;
              border-radius: 2px;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #FFFFFF;" @click="submitUpdatePhone">确认修改</span></div>
        </div>
        <div><span
          style="color: #df2525;font-size: 14px;font-family: Noto Sans S Chinese !important;">{{ messages }}</span>
        </div>
      </el-dialog>
      <el-dialog :before-close="handleCloseUpdateEmail" width="546px" :visible.sync="updateEmail">
        <div slot="title" style="border-bottom: 1px #DDDDDD solid;padding-bottom: 15px;">
          <div style="text-align: left;margin-left: 55px;">
          <span style="text-align:left;font-size: 18px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #333333;">修改邮箱</span>
          </div>
        </div>
        <el-form :model="updateEmailForm" ref="emailRef" :rules="email_rules">
          <el-form-item prop="email" style="width: 426px;">
            <el-input class="custome-input" style="width: 426px;float: none" v-model="updateEmailForm.email"
                      placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="code" style="width: 426px;">
            <el-input placeholder="请输入4位验证码" class="custome-input" style="margin-left: -170px;width: 255px;float: none"
                      v-model="updateEmailForm.code"></el-input>
            <span><img style="width: 170px;height: 38px;position: absolute" :src="qrcodeUrl" alt="验证码加载失败..."
                       @click="getValicode"/></span>
          </el-form-item>
          <el-form-item prop="newEmailCode" style="width: 426px;">
            <el-input placeholder="请输入6位新邮箱验证码" class="custome-input" style="margin-left: -170px;width: 255px;float: none"
                      v-model="updateEmailForm.newEmailCode"></el-input>
            <div class="timeButton" v-show="Verification" @click="handleEmailTimeClick">获取动态密码</div>
            <div class="timeButtonClick" v-show="!Verification">{{ timers }}秒后重新获取</div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="height: 65px;margin-top: -5px;">
          <div style="text-align: center;width: 100%;">
          <span class="submitUpdatePhone" style="
              padding: 10px 45px;
              background: #184482;
              border-radius: 2px;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #FFFFFF;" @click="submitUpdateEmail">确认修改</span></div>
        </div>
        <div><span
          style="color: #df2525;font-size: 14px;font-family: Noto Sans S Chinese !important;">{{ messages }}</span>
        </div>
      </el-dialog>
    </div>
    <div class="user-main" v-show="isH5">
      <div class="user-main-head">
        <img @click="clickHanler('/index')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
        <div>账号设置</div>
      </div>
      <div class="account-main-tip">
        我的信息
      </div>
      <div class="account-main-name">
        <div>用 户 名</div>
        <div>{{ userid }}</div>
      </div>
      <div class="account-main-name">
        <div>昵 称</div>
        <div v-if="!updateName">{{ nick ? nick : "未设置" }}</div>
        <div v-if="!hasCurrent" v-show="updateName">
          <el-input ref="updateName" @blur="saveName" class="h5-custome-updateName-input"
                    v-model="nick"></el-input>
        </div>
      </div>
      <div class="account-main-name">
        <div>密 码</div>
        <div @click="!hasCurrent?'javascript:void(0)':toPath('/rePass')">修改密码</div>
      </div>
      <div class="account-main-name">
        <div>手 机</div>
        <div>{{ phone }}</div>
        <div v-if="!hasCurrent" class="custome-update" @click="toPath('/rePhone')">
          <img src="../assets/h5-update.png"/>
        </div>
      </div>
      <div class="account-main-name">
        <div>邮 箱</div>
        <div>{{ email ? email : "未设置" }}</div>
        <div v-if="!hasCurrent" class="custome-update" @click="toPath('/reEmail')">
          <img src="../assets/h5-update.png"/>
        </div>
      </div>
      <div class="account-main-name">
        <div>账号类型</div>
        <div>
          {{ userType === '0' ? "个人账号" : userType === '1' ? "机构子账号" : "机构漫游子账号" }}
          <img v-if="userType==='0'" src="../assets/person_no.png" alt="">
          <img v-if="userType==='1'" src="../assets/child_no.png" alt="">
          <img v-if="userType==='2'" src="../assets/roaming_no.png" alt=""></div>
      </div>
      <div class="account-main-name" v-if="groupUser!==undefined&&groupUser!==null&&groupUser!==''">
        <div>机构名称</div>
        <div>{{ groupUser }}</div>
      </div>
      <div class="account-main-three">
        <div>账户登录关联</div>
        <div>（关联第三方可便捷登录账户）</div>
      </div>
      <div class="account-main-three-select">
        <div>
          <img src="../assets/qq.png" alt=""/>
        </div>
        <div class="account-main-three-select-two">
          <div>
          <span v-show="okQQ"
                style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            已关联
          </span>
            <span v-show="!okQQ"
                  style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            未关联
          </span>
          </div>
          <div style="margin-right: 45px">
          <span v-if="!hasCurrent">
            <div v-show="okQQ" class="h5-ok" @click="bindQQ">取消关联</div>
            <div v-show="!okQQ" class="h5-no" @click="bindQQ">关联QQ</div>
          </span>
          </div>
        </div>
      </div>
      <div class="account-main-three-select" style="margin-bottom: 525px;">
        <div>
          <img src="../assets/wx.png" alt=""/>
        </div>
        <div class="account-main-three-select-two">
          <div>
          <span v-show="okWX"
                style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            已关联
          </span>
            <span v-show="!okWX"
                  style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            未关联
          </span>
          </div>
          <div style="margin-right: 45px">
          <span v-if="!hasCurrent">
            <div v-show="okWX" class="h5-ok" @click="bindWX">取消关联</div>
            <div v-show="!okWX" class="h5-no" @click="bindWX">关联微信</div>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      isH5: false,
      userType: localStorage.getItem("userType"),
      groupUser: localStorage.getItem("groupUser"),
      hasCurrent: false,
      message: "",
      okQQ: false,
      okWX: false,
      messages: '',
      timers: 60,
      Verification: true,
      nick: "",
      userid: "",
      email:"",
      phone: "未设置",
      updateName: false,
      updatePass: false,
      updatePhone: false,
      updateEmail: false,
      updatePassFormWidth: '100px',
      qrcodeUrl: "",
      updatePhoneForm: {
        phone: undefined,
        code: undefined,
        phoneCode: undefined,
      },
      updateEmailForm: {
        email: undefined,
        code: undefined,
        newEmailCode: undefined,
      },
      updatePassForm: {
        oPass: '',
        nPass: '',
        aPass: ''
      },
      updatePassFormRules: {
        oPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'}
        ],
        nPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'}
        ],
        aPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur'},
          {validator: this.validatePass, trigger: 'blur'}
        ],
      },
      isLogin: false,
      phone_rules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1[0-9]{10}$/, message: "请输入合法手机号", trigger: "blur"},
          {validator: this.validatePhone, trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '请输入4位验证码', trigger: 'blur'}
        ],
        phoneCode: [
          {validator: this.validatePhoneCode, trigger: 'blur'}
        ]
      },
      email_rules: {
        email: [
          {required: true, message: '请输入新邮箱', trigger: 'blur'},
          {pattern:  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/, message: "请输入合法邮箱", trigger: "blur"},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '请输入4位验证码', trigger: 'blur'}
        ],
        newEmailCode: [
          {validator: this.validateNewEmailCode, trigger: 'blur'}
        ]
      },
    }
  },
  beforeMount() {
    this.isH5 = this.isMobile()
  },
  created() {
    this.getPhone()
    const authContext = localStorage.getItem("auth-context");
    JSON.parse(authContext).forEach((item, index) => {
      if (item.type !== "Group" && !this.hasCurrent) {
        this.hasCurrent = item.concurrency > 1
      }
    })
  },
  mounted() {
    this.getUserInfo()
    this.getValicode()
    this.getThirdBindStatus()
  },
  methods: {
    clickHanler(url) {
      document.location.href = "https://wesitrans.com/index"
    },
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    updateNameClick() {
      this.updateName = true
      this.nickTmp = this.nick
      this.$refs.updateName.focus()
      this.message = ""
    },
    saveName() {
      if (this.nick && this.nick.length > 12) {
        this.$message.error("昵称超出长度限制")
        this.nick = this.nickTmp
        return
      }
      this.message = "重新登陆后即可查看昵称更改"
      let that = this
      this.updateName = false
      this.$http.get("/api/account/updateName?name=" + this.nick).then(res => {
        let data = res.data;
        if (data) {
          that.$message.success("更改成功")
          return
        }
        that.$message.error("更改失败")
        this.nick = this.nickTmp
      });
    },
    getThirdBindStatus() {
      this.$http.get("/api/account/getThirdBind").then(res => {
        let data = res.data;
        if (data) {
          if (data.length === 0) {
            return
          }
          data.forEach((item, index) => {
            if (item === "wx") {
              this.okWX = true;
            }
            if (item === "qq") {
              this.okQQ = true;
            }
          })
          return;
        }
        this.okQQ = false;
        this.okWX = false;
      }).catch(err => {
      })
    },
    bindQQ() {
      let that = this
      if (this.okQQ) {
        this.$http.get("/api/account/removeBindQQ").then(res => {
          if (res.data) {
            that.$message.success("已取消")
            that.okQQ = false;
            return
          }
          that.$message.error("取消失败")
        }).catch(err => {
          that.$message.error("取消失败")
        })
        return
      }
      if (this.phone === "未设置") {
        this.$message.error("请先绑定手机号")
        return
      }
      this.$http.get("/api/account/getAppId?type=qq").then(res => {
        let data = res.data;
        if (data) {
          window.open('https://graph.qq.com/oauth2.0/authorize?client_id=' + data.appid +
            '&redirect_uri=' + encodeURIComponent("https://wesitrans.com/index") +
            '&response_type=code&scope=get_user_info&state=' + data.state)
          return
        }
        that.$message.error("关联失败")
      }).catch(err => {
      })
    },
    bindWX() {
      let that = this
      if (this.okWX) {
        this.$http.get("/api/account/removeBindWX").then(res => {
          if (res.data) {
            that.$message.success("已取消")
            that.okWX = false;
            return
          }
          that.$message.error("取消失败")
        }).catch(err => {
          that.$message.error("取消失败")
        })
        return
      }
      if (this.phone === "未设置") {
        this.$message.error("请先绑定手机号")
        return
      }
      this.$http.get("/api/account/getAppId?type=wx").then(res => {
        let data = res.data;
        if (data) {
          window.open('https://open.weixin.qq.com/connect/qrconnect?appid=' + data.appid +
            '&redirect_uri=' + encodeURIComponent("https://wesitrans.com") +
            '&response_type=code&scope=snsapi_login&state=' + data.state + '#wechat_redirect')
          return
        }
        that.$message.error("关联失败")
      }).catch(err => {
      })
    },
    handleCloseUdatePass(done) {
      done();
      this.messages = ""
      this.updatePassForm.aPass = undefined
      this.updatePassForm.nPass = undefined
      this.updatePassForm.oPass = undefined
    },
    handleCloseUpdateEmail(done) {
      done();
      this.messages = ""
      this.updateEmailForm.email = undefined
      this.updateEmailForm.code = undefined
      this.updateEmailForm.newEmailCode = undefined
    },
    handleCloseUpdatePhone(done) {
      done();
      this.messages = ""
      this.updatePhoneForm.phone = undefined
      this.updatePhoneForm.code = undefined
      this.updatePhoneForm.phoneCode = undefined
    },
    submitUpadatePass() {
      const that = this
      this.$refs.updatePassForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.get("/api/account/updatePass?oPass=" + this.updatePassForm.oPass + "&nPass=" + this.updatePassForm.nPass + "&aPass=" + this.updatePassForm.aPass).then(res => {
          if (res.data === 200) {
            that.updatePass = false
            that.$message.success("更新成功")
            that.messages = ""
            return;
          }
          if (res.data === 401) {
            that.messages = "密码错误更新失败"
            return;
          }
          that.messages = "更新失败请重试！";
        }).catch(err => {
          that.messages = "更新失败请重试！";
        })
      });
    },
    toPath(url) {
      this.$router.push(url)
    },
    submitUpdatePhone() {
      this.isLogin = true;
      const that = this
      this.$refs.phoneRef.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.get("/api/account/updatePhone?phone=" + that.updatePhoneForm.phone + "&phoneCode=" + that.updatePhoneForm.phoneCode).then(res => {
          if (res.data === 200) {
            that.updatePhone = false
            that.$message.success("更新成功")
            that.getPhone();
            return;
          }
          that.messages = "更新失败请重试！";
          that.getValicode();
          that.isLogin = false;
        }).catch(err => {
          if (err && err.code === 5) {
            this.$router.push("/login")
          }
          that.isLogin = false;
        })
      });
      this.isLogin = false;
    },
    submitUpdateEmail() {
      this.isLogin = true;
      const that = this
      this.$refs.emailRef.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$http.post("/api/account/updateEmail",{
          "email":this.updateEmailForm.email,
          "code":this.updateEmailForm.code,
          "newCode":this.updateEmailForm.newEmailCode
        }).then(res => {
          if (res.data === 200) {
            that.isLogin = false;
            that.updateEmail = false
            that.$message.success("更新成功")
            that.getPhone();
            return;
          }
          that.messages = "更新失败请重试！";
          that.getValicode();
          that.isLogin = false;
        }).catch(err => {
          if (err && err.code === 5) {
            this.$router.push("/login")
          }
          that.isLogin = false;
        })
      });
      this.isLogin = false;
    },
    getPhone() {
      const that = this
      this.$http.get("/api/account/getPhone").then(res => {
        if (res.data) {
          that.phone = res.data.Mobile;
          that.email = res.data.Email;
        }
      }).catch(err => {
      })
    },
    validatePhoneCode(rule, value, callback) {
      if (!this.isLogin) {
        callback()
        return
      }
      if (value === null || value === undefined || value === "") {
        callback(new Error('请输入手机验证码'))
        return;
      }
      if (value.length > 6 || value.length < 6) {
        callback(new Error('请输入6位手机验证码'))
        return;
      }
      callback()
    },
    validatePass(rule, value, callback) {
      if (this.updatePassForm.nPass !== value) {
        callback(new Error('两次密码输入不一致'))
        return;
      }
      callback()
    },
    validatePhone(rule, value, callback) {
      this.$http.get("/api/account/validatePhone?phone=" + value).then(res => {
        if (res.data === 400) {
          callback(new Error('手机号有误'))
          return;
        }
        if (res.data === 401) {
          callback(new Error('手机号已被绑定'))
          return;
        }
        callback()
      }).catch(err => {
        callback(new Error('校验出错'));
      });
    },
    validateNewEmailCode(rule, value, callback) {
      if (!this.isLogin) {
        callback()
        return
      }
      if (value === null || value === undefined || value === "") {
        callback(new Error('请输入新邮箱验证码'))
        return;
      }
      if (value.length > 6 || value.length < 6) {
        callback(new Error('请输入6位新邮箱验证码'))
        return;
      }
      callback()
    },
    validateEmail(rule, value, callback) {
      if(this.email===value.trim()){
        callback(new Error('邮箱与原邮箱一致'))
        return;
      }
      this.$http.get("/api/account/validateEmail?email=" + value).then(res => {
        if (res.data === 400) {
          callback(new Error('邮箱有误'))
          return;
        }
        if (res.data === 401) {
          callback(new Error('邮箱已被绑定'))
          return;
        }
        callback()
      }).catch(err => {
        callback(new Error('校验出错'));
      });
    },
    handleEmailTimeClick() {
      this.$refs.emailRef.validate((valid) => {
        if (!valid) {
          return;
        }
        const that = this;
        this.$http.get('/api/account/sendEmailCode?email=' + that.updateEmailForm.email + "&code=" + that.updateEmailForm.code).then(res => {
          if (res.data) {
            that.messages = "";
            that.Verification = false;
            this.auth_timer = setInterval(() => {
              that.timers--;
              if (that.timers <= 0) {
                that.Verification = true;
                that.timers = 60;
                clearInterval(that.auth_timer)
              }
            }, 1000)
            return;
          }
          that.getValicode();
          that.messages = "验证码错误"
          that.Verification = true;
          that.timers = 60;
        }).catch(err => {
          that.getValicode();
          that.messages = "邮箱验证码发送失败"
          that.Verification = true;
          that.timers = 60;
        });
      });
    },
    handleTimeClick() {
      this.$refs.phoneRef.validate((valid) => {
        if (!valid) {
          return;
        }
        const that = this;
        this.$http.get('/api/account/sendPhoneCode?phone=' + that.updatePhoneForm.phone + "&code=" + that.updatePhoneForm.code).then(res => {
          if (res.data) {
            that.messages = "";
            that.Verification = false;
            this.auth_timer = setInterval(() => {
              that.timers--;
              if (that.timers <= 0) {
                that.Verification = true;
                that.timers = 60;
                clearInterval(that.auth_timer)
              }
            }, 1000)
            return;
          }
          that.getValicode();
          that.messages = "验证码错误"
          that.Verification = true;
          that.timers = 60;
        }).catch(err => {
          that.getValicode();
          that.messages = "手机验证码发送失败"
          that.Verification = true;
          that.timers = 60;
        });
      });
    },
    getUserInfo: function () {
      const that = this
      this.$http.get("/api/account/getInfo").then(res => {
        if (res.data) {
          if (res.data.Name) {
            that.userid = res.data.Name
          }
          if (res.data.RealName) {
            that.nick = res.data.RealName
          }
        }
      }).catch(err => {
      })
    },
    getValicode() {
      const that = this;
      that.qrcodeUrl = '/api/account/getValicode?' + new Date();
    },
  }
}
</script>
<style scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1c50a7;
  color: #FFF;
}
.account-main-three-select-two {
  margin-left: 47px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.h5-ok {
  width: 129px;
  height: 41px;
  border: 1px solid #1C50A7;
  border-radius: 3px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1C50A7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h5-no {
  width: 129px;
  height: 41px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 3px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-main-three {
  width: 100%;
  height: 93px;
  margin-left: 31px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}

.account-main-tip {
  width: 100%;
  height: 80px;
  background: #F5F5F5;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.account-main-three-select {
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 11px;
}

.account-main-name {
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 11px;
}

.account-main-name div:nth-child(1) {
  width: 126px;
}

.account-main-name div:nth-child(2) {
  color: #666666;
}

.user-img {
  width: 16px;
  padding-left: 5px;
}

.submitUpdatePhone {
  padding: 10px 45px;
  background: #184482;
  border-radius: 2px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
}

.submitUpdatePhone:hover {
  padding: 10px 45px;
  background: #1C50A7 !important;
  border-radius: 2px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  cursor: pointer;
}

.submitUpadatePass:hover {
  padding: 10px 45px;
  background: #1C50A7 !important;
  border-radius: 2px;
  font-size: 14px;
  font-family: "Adobe Heiti Std";
  font-weight: normal;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.submitUpadatePass {
  padding: 10px 45px;
  background: rgb(24, 68, 130);
  border-radius: 2px;
  font-size: 14px;
  font-family: "Adobe Heiti Std";
  font-weight: normal;
  color: rgb(255, 255, 255);
}

.h5-custome-updateName-input {
  width: 100%;
  height: 60px;
  background: #F5F5F5;
  border: 0;
}

>>> .h5-custome-updateName-input .el-input__inner {
  width: 500px;
  height: 60px;
  background: #F5F5F5;
  border: 0;
  font-size: 24px;
}

.custome-updateName-input {
  width: 226px;
  height: 30px;
  background: #F5F5F5;
  border: 0;
}

>>> .custome-updateName-input .el-input__inner {
  width: 226px;
  height: 30px;
  background: #F5F5F5;
  border: 0;
}

.timeButton {
  margin-left: -209px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 130px;
  height: 20px;
  float: right;
  background-color: #efeded;
  font-size: 14px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  line-height: 1.7;
  text-align: center;
  color: #333333;
}

.timeButtonClick {
  margin-left: -209px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 20px;
  border: 0px solid #000;
  width: 130px;
  height: 20px;
  float: right;
  background-color: #1c50a7;
  font-size: 14px;
  font-weight: 400;
  font-family: Noto Sans S Chinese !important;
  line-height: 1.7;
  text-align: center;
  color: #ffffff;
}

.custome-input {
  width: 368px;
  height: 40px;
  border: 0px solid #DDDDDD;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  float: left;
}

.custome-label.el-form-item {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.ok {
  cursor: pointer;
  padding: 5px 14px;
  width: 48px;
  height: 16px;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  color: #1C50A7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background: #ffffff
}

.no {
  cursor: pointer;
  padding: 5px 14px;
  width: 51px;
  height: 17px;
  background: #1C50A7;
  border: 1px solid #1C50A7;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.custome-update {
  padding-left: 20px;
  cursor: pointer;
}

.custome-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.custome-title-padding {
  padding-right: 30px;
}


#recharge-div-one {
  background: #FFFFFF;
  overflow: hidden;
  margin-bottom: 20px;
}

#recharge-div {
  background: #FFFFFF;
  overflow: hidden;
  height: 870px;
  text-align: left;
}

#record-div {
  margin-top: 20px;
  height: 700px !important;
  background: #FFFFFF;
}

.title-font {
  width: 96px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #949494;
  margin-top: 27px;
}

.line-div {
  width: 98px;
  height: 1px;
  background: #DDDDDD;
}

#select-div {
  height: 211px;
  /*width: 890px;*/
  /*border: 1px solid #DDDDDD;*/
}

.select-frame {
  float: left;
  margin-left: 42px;
  width: 150px;
  height: 180px;
  background-color: white;
  border: 2px solid #DFB465;
  border-radius: 10px;
  cursor: pointer;
}

.select-frame-active {
  background: #fcf5eb !important;
}

.select-font-1 {
  height: 25px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #27314B;
  margin-top: 15px;
}

.select-font-2 {
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #DFB465;
  text-align: center;
  padding: 20px;
}

.select-font-3 {
  height: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #8F94A7;
  text-align: center;
}
</style>
