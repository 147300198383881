<template>
  <div>
    <div v-show="!isH5" style="width: 100%;overflow: hidden">
      <el-tabs v-model="activeName" @tab-click="handleClick"
               class="custome-tab">
        <el-tab-pane label="套餐充值" name="taocan">
          <el-main id="recharge-div-one">
            <div
              style="text-align: left;margin-left: 45px;font-size: 14px;font-family: Noto Sans S Chinese;font-weight: 500;color: #333333;">
              <img src="../assets/card.png" alt="">
              <div style="    width: 90%;    float: right;">
                <span>钱包余额：</span>
                <span style="color: #333333;font-size: 16px">
          ¥<span style="font-size: 30px">{{ totalMmoney }}</span>
        </span>
              </div>
            </div>
          </el-main>
          <el-main id="recharge-div">
            <!-- 充值显示部分标题 -->
            <div style="width: 890px;height: 100px;">
              <div class="line-div" style="margin-top: 45px;margin-left: 236px;float: left"></div>
              <div style="float: left;margin-top: 33px;margin-left: 5px">
                <img src="../assets/block.png" style="width: 10px">
              </div>
              <span class="title-font center" style="float: left;margin-left: 12px">会员权益</span>
              <div style="float: left;margin-top: 33px;margin-left: 12px">
                <img src="../assets/block.png" style="width: 10px">
              </div>
              <div class="line-div" style="margin-top: 45px;margin-left: 5px;float: left"></div>
            </div>
            <!-- 充值显示部分套餐选项 -->
            <div id="select-div">
              <div class="select-frame" :class="selectOne" @click="celectFrame('one')">
                <div class="select-font-1" style="width: 100%;text-align: center;">套餐一</div>
                <div class="select-font-2">
                  <span style="font-size: 20px">¥</span>
                  <span>100</span>
                </div>
                <div class="select-font-3">
                  <span style="font-size: 20px">赠10元</span>
                </div>
              </div>
              <div class="select-frame" :class="selectTwo" @click="celectFrame('two')">
                <div class="select-font-1" style="width: 100%;text-align: center;">套餐二</div>
                <div class="select-font-2">
                  <span style="font-size: 20px">¥</span>
                  <span>200</span>
                </div>
                <div class="select-font-3">
                  <span style="font-size: 20px">赠25元</span>
                </div>
              </div>
              <div class="select-frame" :class="selectThree" @click="celectFrame('three')">
                <div class="select-font-1" style="width: 100%;text-align: center;">套餐三</div>
                <div class="select-font-2">
                  <span style="font-size: 20px">¥</span>
                  <span>500</span>
                </div>
                <div class="select-font-3">
                  <span style="font-size: 20px">赠60元</span>
                </div>
              </div>
              <div class="select-frame" :class="selectFour" @click="celectFrame('four')">
                <div class="select-font-1" style="width: 100%;text-align: center;">套餐四</div>
                <div class="select-font-2">
                  <span style="font-size: 20px">¥</span>
                  <span>1000</span>
                </div>
                <div class="select-font-3">
                  <span style="font-size: 20px">赠200元</span>
                </div>
              </div>
            </div>
            <div
              style="text-align: left;margin-top: 45px;margin-left: 45px;font-size: 14px;font-family: Noto Sans S Chinese;font-weight: 400;color: #333333;">
              <span>应付金额：</span>
              <span style="color: #e13f3f;font-size: 16px">¥
            <span style="font-size: 30px">{{ money }}</span>
          </span>
              <div style="width: 166px;
          height: 48px;
          background: rgb(24, 68, 130);
          border-radius: 3px;
          float: right;
          margin-right: 35px;
          text-align: center;
          line-height: 48px;cursor: pointer;
         " @click="payMeal"><span style="width: 71px;
height: 18px;
font-size: 18px;
font-family: Noto Sans S Chinese;
font-weight: 400;
color: #FFFFFF;
">确认支付</span>
              </div>
            </div>
          </el-main>
        </el-tab-pane>
        <el-tab-pane label="充值卡充值" name="card">
          <el-main id="recharge-div2">
            <div v-show="!ok" class="custome-content">
              <div class="custome-input">充值卡卡号：
                <el-input v-model="code" clearable></el-input>
              </div>
              <div class="custome-input">充值卡密码：
                <el-input v-model="pass" clearable></el-input>
              </div>
              <div style="padding-top: 10px;margin-bottom: -10px;">
                <span style="color: red;font-size: 12px;font-family: Microsoft YaHei;">{{ message }}</span>
              </div>
              <div style="padding-top: 50px">
                <span class="custome-success" @click="recharge">一键充值</span>
              </div>
              <div style="margin-top:120px;width: 818px;
                  height: 132px;
                  background: #F5F5F5;">
                <div style="text-align: left;padding: 20px 30px;">
                  <div>
                  <span style="font-size: 16px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  使用说明：
                </span>
                  </div>
                  <div style="padding: 15px 0;">
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                 1. 输入会员充值卡的账号和密码即可给当前登录用户充值。
                </span>
                  </div>
                  <div>
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  2.充值成功后会员卡余额全部转到当下登录用户钱包余额中。
                </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="ok" class="custome-content">
              <div style="transform: translate(40%, -20px);">
                <div style="float: left;" class="box"></div>
                <div style="    float: left;
                line-height: 40px;
                padding-left: 20px;">
              <span style="font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;">充值成功</span>
                </div>
              </div>
              <div style="margin-top: 70px;font-family: Microsoft YaHei;font-size: 14px;color: #666666">
                <div><span>卡号：{{ code }}</span></div>
                <div style="padding-top: 20px;"><span>充值时间：{{ time }}</span></div>
              </div>
              <div style="margin-top:185px;width: 818px;
                  height: 132px;
                  background: #F5F5F5;">
                <div style="text-align: left;padding: 20px 30px;">
                  <div>
                  <span style="font-size: 16px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  使用说明：
                </span>
                  </div>
                  <div style="padding: 15px 0;">
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                 1. 输入会员充值卡的账号和密码即可给当前登录用户充值。
                </span>
                  </div>
                  <div>
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  2.充值成功后会员卡余额全部转到当下登录用户钱包余额中。
                </span>
                  </div>
                </div>
              </div>
            </div>
          </el-main>
        </el-tab-pane>
      </el-tabs>
      <el-footer id="record-div">
        <div style="width: 890px;height: 100px;">
          <div class="line-div" style="margin-top: 45px;margin-left: 236px;float: left"></div>
          <div style="float: left;margin-top: 33px;margin-left: 5px">
            <img src="../assets/block.png" style="width: 10px">
          </div>
          <span class="title-font center" style="float: left;margin-left: 12px">充值记录</span>
          <div style="float: left;margin-top: 33px;margin-left: 12px">
            <img src="../assets/block.png" style="width: 10px">
          </div>
          <div class="line-div" style="margin-top: 45px;margin-left: 5px;float: left"></div>
        </div>
        <div>
          <el-table
            :height="400"
            :header-cell-style="{ 'background': '#eeeeee','font-weight': '400','color': '#666666','font-family':'Source Han Sans CN'}"
            :data="tableData"
            style="width: 100%;">
            <el-table-column
              align="center"
              prop="createTime"
              label="时间"
              width="180">
            </el-table-column>
            <el-table-column
              align="center"
              prop="amount"
              label="充值金额"
              width="180">
            </el-table-column>
            <el-table-column
              align="center"
              prop="memo"
              label="赠送">
            </el-table-column>
            <el-table-column
              align="center"
              prop="zonge"
              label="总额">
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: right;" v-if="mealTotal!==0&&mealTotal>10">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="10"
            :total="mealTotal"
            @prev-click="prevClick"
            @next-click="nextClick"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </el-footer>
    </div>
    <div class="user-main" v-show="isH5">
      <div class="user-main-head">
        <img @click="clickHanler('/index')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
        <div>用户充值</div>
      </div>
      <div class="user-main-select">
        <div @click="()=>activeName='taocan'" :class="activeName==='taocan'?'ischeck':''">套餐充值</div>
        <div @click="()=>activeName='card'" :class="activeName==='card'?'ischeck':''">充值卡充值</div>
      </div>
      <div v-show="activeName==='taocan'">
        <div style="display: flex;align-items: center;margin-top:26px;width: 768px;height: 80px;background: #FFFFFF;">
          <img style="margin-left: 35px;margin-right: 27px" src="../assets/card.png" alt="">
          <div style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            钱包余额：<span style="color:rgba(225, 63, 63, 1);">¥<span
            style="color:rgba(225, 63, 63, 1);font-size: 30px">{{ totalMmoney }}</span></span>
          </div>
        </div>
        <div style="display: flex; flex-flow: column;margin-top: 27px;width: 768px;height: 1120px;background: #FFFFFF;">
          <div style="padding-top: 40px;display: flex;align-items: center;justify-content: center;">
            <div style="width: 110px;height: 1px;background: #DDDDDD;margin-right: 6px"></div>
            <div
              style="transform: rotate(7deg); -ms-transform: rotate(50deg); -moz-transform: rotate(50deg); -webkit-transform: rotate(50deg); -o-transform: rotate(50deg);width: 18px;height: 18px;background: #DDDDDD;"></div>
            <div style="margin: 0 19px;font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
              会员权益
            </div>
            <div
              style="transform: rotate(7deg); -ms-transform: rotate(50deg); -moz-transform: rotate(50deg); -webkit-transform: rotate(50deg); -o-transform: rotate(50deg);width: 18px;height: 18px;background: #DDDDDD;"></div>
            <div style="width: 110px;height: 1px;background: #DDDDDD;margin-left: 6px"></div>
          </div>
          <div style="margin-top: 58px;display: flex;align-items: center;justify-content: center;">
            <div class="select-frame-h5" style="margin-right: 46px;margin-bottom:36px " :class="selectOne"
                 @click="celectFrame('one')">
              <div
                style="margin-top:54px;text-align: center;font-size: 36px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
                套餐一
              </div>
              <div class="select-font-2">
                <span style="font-size: 24px">¥</span>
                <span style="font-size: 64px">100</span>
              </div>
              <div class="select-font-3">
                <span style="font-size: 30px">赠10元</span>
              </div>
            </div>
            <div class="select-frame-h5" style="margin-bottom:36px " :class="selectTwo" @click="celectFrame('two')">
              <div
                style="margin-top:54px;text-align: center;font-size: 36px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
                套餐二
              </div>
              <div class="select-font-2">
                <span style="font-size: 24px">¥</span>
                <span style="font-size: 64px">200</span>
              </div>
              <div class="select-font-3">
                <span style="font-size: 30px">赠25元</span>
              </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;">
            <div class="select-frame-h5" style="margin-right: 46px;margin-bottom:36px " :class="selectThree"
                 @click="celectFrame('three')">
              <div
                style="margin-top:54px;text-align: center;font-size: 36px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
                套餐三
              </div>
              <div class="select-font-2">
                <span style="font-size: 24px">¥</span>
                <span style="font-size: 64px">500</span>
              </div>
              <div class="select-font-3">
                <span style="font-size: 30px">赠60元</span>
              </div>
            </div>
            <div class="select-frame-h5" style="margin-bottom:36px " :class="selectFour" @click="celectFrame('four')">
              <div
                style="margin-top:54px;text-align: center;font-size: 36px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;">
                套餐四
              </div>
              <div class="select-font-2">
                <span style="font-size: 24px">¥</span>
                <span style="font-size: 64px">1000</span>
              </div>
              <div class="select-font-3">
                <span style="font-size: 30px">赠200元</span>
              </div>
            </div>

          </div>
          <div style="margin-right: 30px;display: flex;    flex-flow: column;align-items: flex-end;">
            <div style="font-size: 30px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">应付金额：
              <span style="color: #e13f3f;font-size: 30px">¥ <span style="font-size: 30px">{{
                  money
                }}</span></span></div>
            <div @click="payMeal"
                 style="margin-top: 27px;display: flex;align-items: center;justify-content: center;font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;width: 260px;height: 80px;background: #063F95;">
              确认支付
            </div>
          </div>
        </div>
        <div style="display: flex;flex-flow: column;align-items: center;margin-top:25px;background: #FFFFFF;">
          <div style="padding-top: 40px;display: flex;align-items: center;justify-content: center;">
            <div style="width: 110px;height: 1px;background: #DDDDDD;margin-right: 6px"></div>
            <div
              style="transform: rotate(7deg); -ms-transform: rotate(50deg); -moz-transform: rotate(50deg); -webkit-transform: rotate(50deg); -o-transform: rotate(50deg);width: 18px;height: 18px;background: #DDDDDD;"></div>
            <div style="margin: 0 19px;font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
              会员权益
            </div>
            <div
              style="transform: rotate(7deg); -ms-transform: rotate(50deg); -moz-transform: rotate(50deg); -webkit-transform: rotate(50deg); -o-transform: rotate(50deg);width: 18px;height: 18px;background: #DDDDDD;"></div>
            <div style="width: 110px;height: 1px;background: #DDDDDD;margin-left: 6px"></div>
          </div>
          <div
            style="display: flex;align-items: center;justify-content: space-evenly;width: 709px;margin-top:18px;height: 68px;background: #F5F5F5;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
            <div>时间</div>
            <div>充值金额</div>
            <div>赠送</div>
            <div>总额</div>
          </div>
          <div v-for="item in tableData">
            <div
              style="display: flex;align-items: center;justify-content: space-evenly;width: 709px;margin-top:18px;height: 68px;font-size: 24px;font-family: Adobe Heiti Std;font-weight: normal;color: #666666;">
              <div>{{ item.createTime }}</div>
              <div>{{ item.amount }}</div>
              <div>{{ item.memo }}</div>
              <div>{{ item.zonge }}</div>
            </div>
          </div>
          <div style="height: 60px;display: flex;justify-content: center;align-items: center;width: 100%;"
               v-if="mealTotal!==0&&mealTotal>10">
            <el-pagination
              class="el-page"
              background
              layout="prev, pager, next"
              :page-size="10"
              :total="mealTotal"
              @prev-click="prevClick"
              @next-click="nextClick"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div v-show="activeName!=='taocan'">
        <div class="user-main-select-taocan">
          <div v-show="!ok" class="custome-content-h5">
            <div class="custome-input-h5">充值卡卡号：
              <el-input v-model="code" clearable></el-input>
            </div>
            <div class="custome-input-h5">充值卡密码：
              <el-input v-model="pass" clearable></el-input>
            </div>
            <div style="padding-top: 10px;margin-bottom: -10px;">
              <span style="color: red;font-size: 24px;font-family: Microsoft YaHei;">{{ message }}</span>
            </div>
            <div style="padding-top: 50px">
              <div class="custome-success-h5" @click="recharge">一键充值</div>
            </div>
            <div style="margin-top:457px;width: 710px;height: 153px;background: #F5F5F5;">
              <div style="text-align: left;padding: 15px 30px;">
                <div>
                  <span style="font-size: 24px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #333333;">
                  使用说明：
                </span>
                </div>
                <div style="padding: 15px 0;">
                  <span style="font-size: 24px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #333333;">
                 1. 输入会员充值卡的账号和密码即可给当前登录用户充值。
                </span>
                </div>
                <div>
                  <span style="font-size: 24px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #333333;">
                  2.充值成功后会员卡余额全部转到当下登录用户钱包余额中。
                </span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="ok" class="custome-content">
            <div style="transform: translate(40%, -20px);">
              <div style="float: left;" class="box"></div>
              <div style="    float: left;
                line-height: 40px;
                padding-left: 20px;">
              <span style="font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;">充值成功</span>
              </div>
            </div>
            <div style="margin-top: 70px;font-family: Microsoft YaHei;font-size: 14px;color: #666666">
              <div><span>卡号：{{ code }}</span></div>
              <div style="padding-top: 20px;"><span>充值时间：{{ time }}</span></div>
            </div>
            <div style="margin-top:185px;width: 818px;
                  height: 132px;
                  background: #F5F5F5;">
              <div style="text-align: left;padding: 20px 30px;">
                <div>
                  <span style="font-size: 16px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  使用说明：
                </span>
                </div>
                <div style="padding: 15px 0;">
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                 1. 输入会员充值卡的账号和密码即可给当前登录用户充值。
                </span>
                </div>
                <div>
                  <span style="font-size: 14px; font-family: Microsoft YaHei; font-weight: bold; color: #999999;">
                  2.充值成功后会员卡余额全部转到当下登录用户钱包余额中。
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Meal",
  data() {
    return {
      message: "",
      time: undefined,
      ok: false,
      code: "",
      pass: "",
      activeName: 'taocan',
      selectOne: 'select-frame-active',
      selectTwo: '',
      selectThree: '',
      selectFour: '',
      tableData: [],
      mealTotal: 0,
      money: 100,
      totalMmoney: 0,
      meal: 'MealOne',
      isH5: false,
    }
  },
  beforeMount() {
    this.isH5 = this.isMobile()
  },
  mounted() {
    this.getRechargeOrder(0, 10);
    this.getTotalMoney();
  },
  methods: {
    clickHanler(url) {
      document.location.href = "https://wesitrans.com/index"
    },
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    getNowFormatDate(date) {
      var seperator1 = "-";
      var seperator2 = ":";
      var year = date.getFullYear();
      var month = this.numberFormat(date.getMonth() + 1);
      var strDate = this.numberFormat(date.getDate());
      var hours = this.numberFormat(date.getHours());
      var minutes = this.numberFormat(date.getMinutes());
      var seconds = this.numberFormat(date.getSeconds());
      var currentdate = year + seperator1 + month + seperator1 + strDate
        + " " + hours + seperator2 + minutes
        + seperator2 + seconds;
      return currentdate;
    },
    numberFormat(number) {
      return number > -1 && number < 10 ? "0" + number : number;
    },
    recharge() {
      const that = this;
      if (this.code === "" || this.code === undefined || this.code === null) {
        this.message = "充值卡卡号不能为空"
        return
      }
      if (this.pass === "" || this.pass === undefined || this.pass === null) {
        this.message = "充值卡密码不能为空"
        return
      }
      this.$http.post('/api/recharge/card', {
        code: this.code,
        pass: this.pass
      }).then(res => {
        let data = res.data;
        if (data === "" || data === undefined) {
          that.ok = true
          that.time = this.getNowFormatDate(new Date())
          that.getRechargeOrder(0, 10)
          this.getTotalMoney();
          return
        }
        this.message = data
      }).catch(err => {
        this.message = "充值失败请重试"
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTotalMoney() {
      const that = this;
      this.$http.post('/api/recharge/getTotalMoney').then(function (response) {
        that.totalMmoney = response.data
      }).catch(function (error) {
        that.totalMmoney = 0
      });
    },
    nextClick(val) {
      this.getRechargeOrder(val, 10)
    },
    prevClick(val) {
      this.getRechargeOrder(val, 10)
    },
    currentChange(val) {
      this.getRechargeOrder(val, 10)
    },
    celectFrame(val) {
      this.selectOne = '';
      this.selectTwo = '';
      this.selectThree = '';
      this.selectFour = '';
      switch (val) {
        case 'one':
          this.selectOne = 'select-frame-active';
          this.money = 100;
          this.meal = "MealOne"
          break;
        case 'two':
          this.selectTwo = 'select-frame-active';
          this.money = 200;
          this.meal = "MealTwo"
          break;
        case 'three':
          this.selectThree = 'select-frame-active';
          this.money = 500;
          this.meal = "MealThree"
          break;
        case 'four':
          this.selectFour = 'select-frame-active';
          this.money = 1000;
          this.meal = "MealFour"
          break;

      }
    },
    getRechargeOrder(currentPage, pageSize) {
      const that = this;
      this.$http.post('/api/getRechargeOrder', {
        "currentPage": currentPage,
        "pageSize": pageSize
      }).then(function (response) {
        if (response.data) {
          that.mealTotal = response.data.totalCount;
          that.tableData = [];
          response.data.orders.forEach((item, index) => {
            const data = {}
            data.createTime = item.createTime
            data.memo = "+" + item.memo
            data.zonge = item.amount + parseFloat(item.memo)
            let payChannel = item.payChannel
            if (payChannel !== null && payChannel !== undefined && payChannel !== "" && payChannel === "601") {
              data.amount = "充值卡充值 " + item.amount + "元";
            } else if (payChannel === '6') {
              data.amount = "退款金额 " + item.amount + "元";
            } else if (payChannel === '7') {
              data.amount = "参与奖 " + item.amount + "元";
            } else {
              switch (item.amount) {
                case 100 :
                  data.amount = "充值套餐一 100元";
                  break;
                case 200:
                  data.amount = "充值套餐二 200元";
                  break;
                case 500:
                  data.amount = "充值套餐三 500元";
                  break;
                case 1000:
                  data.amount = "充值套餐四 1000元";
                  break;
                default:
                  data.amount = "注册赠送 18元";
                  data.memo = "+0"
                  break;
              }
            }
            that.tableData.push(data)
          });
        }
      }).catch(function (error) {
      });
    },
    payMeal() {
      const that = this;
      let payUrl = process.env.VUE_APP_PAY;
      this.$http.post('/api/recharge/getRequest', {
        mealType: this.meal
      }).then(function (response) {
        if (response.data) {
          window.open(payUrl + response.data, "_self")
          return
        }
        that.$message({
          type: 'info',
          message: '请稍后再试!'
        });
      }).catch(function (error) {
        that.$message({
          type: 'info',
          message: '请稍后再试!'
        });
      });
    }
  }
}
</script>

<style scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1c50a7;
  color: #FFF;
}

/deep/ .el-page.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(6, 63, 149);
  color: #FFF;
}

/deep/ .el-page.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 50px;
  border-radius: 2px;
}

/deep/ .el-page .el-pager li {
  padding: 0 4px;
  background: #FFF;
  font-size: 22px;
  min-width: 35.5px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  text-align: center;
}

/deep/ .el-page.el-pagination button, .el-pagination span:not([class*=suffix]) {
  display: inline-block;
  font-size: 13px;
  min-width: 35.5px;
  height: 40px;
  line-height: 28px;
  vertical-align: top;
  box-sizing: border-box;
}

/deep/ .el-page.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 60px;
  border-radius: 2px;
}

/deep/ .el-page.el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 60px;
  border-radius: 2px;
}

.select-frame-h5 {
  width: 319px;
  height: 366px;
  background: #FFFFFF;
  border: 1px solid #E8E9ED;
  box-shadow: 0px 0px 3px 0px rgba(3, 27, 63, 0.1);
  border-radius: 10px;
}

.ischeck {
  border-bottom: 3px solid #063F95;
}

.user-main-select div:nth-child(1) {
  margin-left: 34px;
  margin-right: 63px;
  height: 80px;
  display: flex;
  align-items: center;
}

.user-main-select div:nth-child(2) {
  height: 80px;
  display: flex;
  align-items: center;
}

.user-main-select {
  width: 768px;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.user-main {
  min-width: 765px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
}

.user-main-select-taocan {
  width: 768px;
  height: 1005px;
  background: #FFFFFF;
  margin-top: 26px;
}

.user-main-head {
  width: 100%;
  height: 88px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
}

.user-main-head div {
  flex: 1;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-right: 60px;
}


.box {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 5px solid #0EB4B7;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0EB4B7;
}

.box::before {
  content: "";
  display: block;
  width: 22px;
  height: 10px;
  border: 4px solid #ffffff;
  border-right: none;
  border-top: none;
  transform: rotate(
    -45deg
  ) translate(3px, -2px);
}

.custome-success {
  background: #184482;
  border-radius: 2px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 45px;
}

.custome-success-h5 {
  width: 260px;
  height: 80px;
  background: #063F95;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custome-success:hover {
  background: #1C50A7;
  border-radius: 2px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 45px;
}

.custome-content {
  padding-top: 60px;
}

.custome-content-h5 {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 30px;
}

.custome-input-h5 {
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
}

>>> .custome-input-h5 .el-input {
  width: 553px;
  padding: 11px;
}

>>> .custome-input-h5 .el-input .el-input__inner {
  width: 553px;
  height: 65px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

>>> .custome-input .el-input {
  width: 470px;
  padding: 11px;
}

>>> .custome-input .el-input .el-input__inner {
  width: 470px;
  height: 40px;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.custome-tab {
  height: 640px;
}

>>> .custome-tab .el-tabs__header {
  height: 60px;
  margin: 0;
}

>>> .custome-tab .el-tabs__header .el-tabs__nav-wrap {
  height: 60px;
}

>>> .custome-tab .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll {
  height: 60px;
  background: white;
  padding-left: 45px;
}

>>> .custome-tab .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav {
  height: 60px;
  line-height: 4;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

>>> .custome-tab .el-tabs__active-bar {
  background-color: #1C50A7;
}

>>> .custome-tab .el-tabs__item.is-active {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1C50A7;
}

>>> .custome-tab .el-tabs__item {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

>>> .custome-tab .el-tabs__item:hover {
  color: #1C50A7;
}

#recharge-div-one {
  background: #FFFFFF;
  overflow: hidden;
  margin-bottom: 20px;
}

#recharge-div {
  background: #FFFFFF;
  overflow: hidden;
  height: 480px;
}

#recharge-div2 {
  background: #FFFFFF;
  overflow: hidden;
  height: 580px;
}

#record-div {
  margin-top: 20px;
  height: 700px !important;
  background: #FFFFFF;
}

.title-font {
  width: 96px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #949494;
  margin-top: 27px;
}

.line-div {
  width: 98px;
  height: 1px;
  background: #DDDDDD;
}

#select-div {
  height: 211px;
  /*width: 890px;*/
  /*border: 1px solid #DDDDDD;*/
}

.select-frame {
  float: left;
  margin-left: 42px;
  width: 150px;
  height: 180px;
  background-color: white;
  border: 2px solid #DFB465;
  border-radius: 10px;
  cursor: pointer;
}

.select-frame-active {
  background: #fcf5eb !important;
  border: 2px solid #DFB465;
}

.select-font-1 {
  height: 25px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #27314B;
  margin-top: 15px;
}

.select-font-2 {
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #DFB465;
  text-align: center;
  padding: 20px;
}

.select-font-3 {
  height: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #8F94A7;
  text-align: center;
}
</style>
